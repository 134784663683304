import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import CustomTextField from "../components/CustomTextField";
import PasswordTextField from "../components/PasswordTextField";

import {
  verifyAccount,
  setAccountPassword,
  forgotPasswordRequest,
} from "../store/actions/Account";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright ©  "}
      <Link color="inherit" href="#">
        impruapp.com
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://fakeimg.pl/350x200/?text=Hello)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const emailregexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const [step, setstep] = useState(1);
  const user = useSelector((state) => state.auth.user);
  const [email, setemail] = useState("");
  const [code, setCode] = useState("");
  const [password, setpassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [emailError, setemailError] = useState("");
  const [codeError, setCodeError] = useState("");
  const [passwordError, setpasswordError] = useState("");
  const [confirmPasswordError, setconfirmPasswordError] = useState("");
  const setpasswordSuccess = useSelector(
    (state) => state.account.setpasswordSuccess
  );
  const error = useSelector((state) => state.account.error);

  if (setpasswordSuccess) {
    return <Redirect to="/sign-in" />;
  }
  const handleEmailChange = (e) => {
    e.preventDefault();
    setemail(e.target.value);
    setemailError("");
  };
  const handleCodeChange = (e) => {
    e.preventDefault();
    setCode(e.target.value);
    setCodeError("");
  };
  const handlePasswordChange = (e) => {
    e.preventDefault();
    setpassword(e.target.value);
    setpasswordError("");
  };
  const handleConfirmPasswordChange = (e) => {
    e.preventDefault();
    setconfirmPassword(e.target.value);
    setconfirmPasswordError("");
  };
  const validateEmail = () => {
    if (email == "") {
      setemailError("Email is required");
      return false;
    }
    if (email.length > 0) {
      if (!emailregexp.test(email)) {
        setemailError("Invalid Email");
        return false;
      }
    }
    return true;
  };

  const validate = () => {
    if (code == "") {
      setCodeError("Code is required");
      return false;
    }
    if (code.length < 6) {
      setCodeError("Invalid Code");
      return false;
    }
    if (code.length > 6) {
      setCodeError("Invalid Code");
      return false;
    }
    if (password == "") {
      setpasswordError("Password is Required");
      return false;
    }
    if (password.length < 8) {
      setpasswordError("Password is too small");
      return false;
    }
    if (confirmPassword == "") {
      setconfirmPasswordError("Confirm Password is Required");
      return false;
    }

    if (password !== confirmPassword) {
      setpasswordError("Passwords do not match");
      setconfirmPasswordError("Passwords do not match");
      return false;
    }
    return true;
  };
  const onClickRequestForgotPassword = () => {
    const isEmailValid = validateEmail();

    if (isEmailValid) {
      console.log("valid");
      dispatch(forgotPasswordRequest(email));
      setstep(step + 1);
    }
  };
  const onClickSetPassword = () => {
    const isValid = validate();
    if (isValid) {
      dispatch(setAccountPassword({ email, code, password, confirmPassword }));
    }
  };
  const step1 = (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Enter your Impru Email
          </Typography>

          <CustomTextField
            label="Email"
            value={email}
            onChange={handleEmailChange}
            error={emailError}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={onClickRequestForgotPassword}
            style={{ width: 360 }}
          >
            Send Email
          </Button>
        </div>
      </Grid>
    </Grid>
  );
  switch (step) {
    case 1:
      return step1;
    case 2:
      return (
        <Grid container component="main" className={classes.root}>
          <CssBaseline />
          <Grid item xs={false} sm={4} md={7} className={classes.image} />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Enter Code and Password
              </Typography>
              <Typography variant="body1">
                Code is sent to your mail. Please check your email {email}
              </Typography>

              <CustomTextField
                label="Code"
                value={code}
                onChange={handleCodeChange}
                error={codeError}
              />
              <Typography variant="body1">Create your Password</Typography>
              <PasswordTextField
                label="Password"
                value={password}
                onChange={handlePasswordChange}
                error={passwordError}
              />
              <PasswordTextField
                label="Confirm Password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                error={confirmPasswordError}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={onClickSetPassword}
              >
                Create Password
              </Button>
            </div>
          </Grid>
        </Grid>
      );
    default:
      return step1;
  }
};
export default ForgotPassword;

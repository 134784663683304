import React, { useEffect } from "react";
import Drawer from "../components/Drawer";

import { makeStyles, Fab, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import GroupTab from "../components/GroupTab";
import Breadcrumbs from "../components/Breadcrumbs";
import { useSelector } from "react-redux";

const GroupDetail = (props) => {
  //const classes = useStyles();

  const group = useSelector((state) => state.groups.group);
  const isLoading = useSelector((state) => state.groups.isLoading);

  useEffect(() => {
    if (group == null && isLoading === false) {
      props.history.push("/");
    }
  }, [props.history, group, isLoading]);

  return (
    <div>
      <Drawer>
        <Breadcrumbs />
        <div
          style={{
            //width: 800,
            alignItems: "center",
            justifyContent: "center",
            //alignSelf: "center",
            //alignContent: "center",
            //flex: 1,
            //  display: "flex",
            //flexDirection: "column",
          }}
        >
          <div>
            <div style={{ width: 800 }}>
              {group !== null ? <GroupTab group={group} /> : <div />}
            </div>
          </div>
        </div>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            bottom: 0,
            right: 0,
            marginBottom: 16,
            marginRight: 16,
            position: "fixed",
          }}
        >
          <Tooltip title="Add Teacher" aria-label="add">
            <Fab
              color="primary"
              aria-label="add"
              onClick={() => {
                props.history.push("/add-teacher");
              }}
            >
              <AddIcon style={{ color: "white" }} />
            </Fab>
          </Tooltip>
        </div> */}
      </Drawer>
    </div>
  );
};

export default GroupDetail;

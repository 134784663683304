import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import useParams from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { baseUrl } from "../config/baseUrl";
import { useSelector, useDispatch } from "react-redux";

import scienceImage from "../assets/images/content-provider.png";
import {
  CardMedia,
  Card,
  CardActionArea,
  Typography,
  Button,
  CardActions,
  CardContent,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 245,
    minWidth: 200,
    margin: 8,
  },
  media: {
    height: 100,
    width: 100,
    //borderRadius: 60,
  },
}));

const ContentProviderItem = (props) => {
  const classes = useStyles();
  const instituteId = useSelector((state) => state.institutes.instituteId);
  const [instituteContentProviders, setInstituteContentProviders] = useState(
    []
  );

  const getAllContentProvidersByInstituteId = async () => {
    const token = localStorage.getItem("token");
    console.log("get content provider");
    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token,
        },
      };

      try {
        const res = await axios.get(
          `${baseUrl}/api/institute-content/content-provider/${instituteId}`,
          config
        );
        console.log("get conent providers" + JSON.stringify(res));
        if (res.status == 200) {
          setInstituteContentProviders(res.data.instituteContentProviders);
        }
      } catch (err) {}
    }
  };

  const addContentProvider = async () => {
    const token = localStorage.getItem("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token,
        },
      };
      console.log("props id" + props.contentProviderId);
      const body = JSON.stringify({
        instituteId,
        contentProviderId: props.contentProviderId,
      });
      try {
        const res = await axios.post(
          `${baseUrl}/api/institute-content/content-provider/add`,
          body,
          config
        );
        // console.log("conent providers" + JSON.stringify(res));
        if (res.status == 200) {
          getAllContentProvidersByInstituteId();
          console.log("res" + res.data.message);
        } else {
          console.log(res);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const removeContentProvider = async (instituteContentProviderId) => {
    const token = localStorage.getItem("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        const res = await axios.delete(
          `${baseUrl}/api/institute-content/content-provider/${instituteContentProviderId}`,
          config
        );

        if (res.status == 200) {
          getAllContentProvidersByInstituteId();
        }
      } catch (err) {}
    }
  };
  useEffect(() => {
    getAllContentProvidersByInstituteId();
  }, []);

  return (
    <Card className={classes.root} key={props.contentProviderId}>
      <div style={{ justifyContent: "center", display: "flex", marginTop: 8 }}>
        <CardMedia
          className={classes.media}
          image={props.imageUrl ? props.imageUrl : scienceImage}
          title="Contemplative Reptile"
        />
      </div>
      <CardContent>
        <Typography
          style={{
            textAlign: "center",
          }}
          gutterBottom
          variant="subtitle1"
          component="h4"
        >
          {props.name}
        </Typography>
        {/* <div style={{ flexDirection: "row" }}>
          <div
            style={{
              justifyContent: "flex-start",
              display: "flex",
              marginTop: 8,
            }}
          >
            <CardMedia
              className={classes.media}
              image={props.imageUrl ? props.imageUrl : scienceImage}
              title="Contemplative Reptile"
            />
          </div>
          <Typography
            style={{
              padding: 0,
            }}
            gutterBottom
            variant="subtitle1"
            component="h4"
          >
            {props.name}
          </Typography>
        </div> */}
      </CardContent>

      <CardActions
        style={{
          justifyContent: "center",
          backgroundColor: "whitesmoke",
        }}
      >
        {instituteContentProviders.some(
          (icp) => icp.contentProviderId == props.contentProviderId
        ) ? (
          <Button
            size="small"
            color="primary"
            onClick={() => {
              removeContentProvider(
                instituteContentProviders.find(
                  (icp) => icp.contentProviderId == props.contentProviderId
                )._id
              );
            }}
          >
            Remove
          </Button>
        ) : (
          <Button size="small" color="primary" onClick={addContentProvider}>
            Add
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default ContentProviderItem;

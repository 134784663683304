import React, { useEffect } from "react";
import Drawer from "../components/Drawer";
//import Typography from "@material-ui/core/Typography";
import Select from "../components/Select";
import CustomTextField from "../components/CustomTextField";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles, Button, Typography, Grid } from "@material-ui/core";
import Breadcrumbs from "../components/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { createMainInstituteAdmin } from "../store/actions/MainInstituteAdmins";
import { getAllMainInsitutes } from "../store/actions/MainInstitute";
import {
  getAllInstitutesByMainInstiuteId
} from "../store/actions/Institutes";
import InstituteCardItem from "../components/InstituteCardItem";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const CreateMIAdmin = (props) => {
  const classes = useStyles();
  const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  const dispatch = useDispatch();
  const mainInstitutes = useSelector((state) => state.mainInstitute.mainInstitutes);
  const insts = useSelector((state) => state.institutes.institutes);
  const message = useSelector((state) => state.teachers.message);
  const error = useSelector((state) => state.teachers.error);
  const alert = useSelector((state) => state.alert.alert);
  const [mainInstitute, setMainInstitute] = React.useState("");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phoneNumber, setPhone] = React.useState("");

  const [institutes, setInstitutes] = React.useState([]);
  const [mainInstituteError, setMainInstituteError] = React.useState("");
  const [nameError, setNameError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");
  const [instituteError, setInstituteError] = React.useState("");
  const [commonError, setCommonError] = React.useState("");

  useEffect(() => {
    dispatch(getAllMainInsitutes());
    setInstitutes(insts)
  }, [dispatch, insts]);
  const handleMainInstituteChange = (e) => {
    e.preventDefault();
    //console.log("change");
    //console.log(e.target.value);
    setMainInstitute([e.target.value]);
    dispatch(
      getAllInstitutesByMainInstiuteId(mainInstitutes[e.target.value]._id)
    );
    setMainInstituteError("");
  };

  const handleNameChange = (e) => {
    e.preventDefault();
    //console.log("change");
    //console.log(e.target.value);
    setName(e.target.value);
    setNameError("");
    setCommonError("")
  };
  const handleEmailChange = (e) => {
    e.preventDefault();
    //console.log("change");
    //console.log(e.target.value);
    setEmail(e.target.value);
    setEmailError("");
    setCommonError("")
  };

  const handlePhoneNumberChange = (e) => {
    e.preventDefault();
    // console.log("change");
    // console.log(e.target);
    setPhone(e.target.value);
    setPhoneError("");
    setCommonError("")
  };
  const onSelectInstitutes = (ins) => {
    // console.log(sub);
    const ints = institutes.map((s) => {
      if (ins._id === s._id) {
        // console.log(s);
        s.isSelected = !s.isSelected;
      }
      return s;
    });
    setInstitutes(ints);
    setInstituteError("");
    setCommonError("")
  };

  const validate = () => {
    if (mainInstitute === "") {
      setMainInstituteError("Main Institute  is Required");
      setCommonError("Main Institute  is Required");

      return false;
    }
    if (name === "") {
      setNameError("Name is required");
      setCommonError("Name is required");
      return false;
    }
    if (email === "") {
      setEmailError("Email is required");
      setEmailError("Email is required");
      return false;
    }
    if (email.length > 0) {
      if (!regexp.test(email)) {
        setEmailError("Invalid Email");
        setCommonError("Invalid Email");
        return false;
      }
    }
    if (phoneNumber === "") {
      setPhoneError("Phone is required");
      setCommonError("Phone is required");
      return false;
    }
    if (phoneNumber.length > 0) {
      if (!phoneno.test(phoneNumber)) {
        setPhoneError("Invalid Phone Number");
        setCommonError("Invalid Phone Number");
        return false;
      }
    }
    let instIds = [];
    if (institutes) {
      institutes.map((sub) => {
        if (sub.isSelected === true) {
          instIds.push(sub._id);
        }
      });
    }
    if (instIds.length <= 0) {
      setInstituteError("Select Atleast one Institute");
      setCommonError("Select Atleast one Institute");
      return false;
    }

    return true;
  };
  const goBack = () => {
    props.history.goBack();
  };

  const onClickCreateMIAdmin = () => {
    const isValid = validate();
    let instIds = [];
    if (isValid) {

      if (institutes) {
        institutes.map((sub) => {
          if (sub.isSelected === true) {
            instIds.push(sub._id);
          }
        });
      }
      dispatch(
        createMainInstituteAdmin({
          mainInstituteId: mainInstitutes[mainInstitute]._id,
          instituteIds: instIds,
          name,
          email,
          phoneNumber,
          isAdmin: true,
        })
      );

      //console.log("is valid");
      setMainInstitute("");
      setName("");
      setEmail("");
      setPhone("");
    }
  };
  return (
    <div>
      <Drawer>
        <Breadcrumbs />
        <div
          style={{
            alignItems: "flex-start",
            justifyContent: "flex-start",
            // alignSelf: "center",
            display: "flex",
            flexDirection: "column",
            // marginLeft: 40,
          }}
        >
          <Select
            options={mainInstitutes}
            firstMenu="Select Institute"
            label="Institute"
            value={mainInstitute}
            onChange={handleMainInstituteChange}
            error={mainInstituteError}
          />

          <CustomTextField
            label="Name"
            value={name}
            onChange={handleNameChange}
            error={nameError}
          />
          <CustomTextField
            label="Email"
            value={email}
            onChange={handleEmailChange}
            error={emailError}
          />
          <CustomTextField
            label="Phone Number"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            error={phoneError}
          />
          <div
            style={{
              display: "flex",
              // flex: 1,
              // alignItems: "flex-start",
              justifyContent: "space-around",
            }}
          >
            <Grid
              //style={{ display: "flex", flex: 1 }}
              container
              // justify="center"
              //alignItems="center"
              spacing={2}
            >
              {institutes.length > 0 ? (
                institutes.map((ins, i) => (
                  <Grid
                    key={i.toString()}
                    style={{

                      textAlign: "center",
                    }}
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    xl={6}
                  // spacing={2}
                  >
                    <InstituteCardItem
                      onChange={() => onSelectInstitutes(ins)}
                      institute={ins}
                    />
                  </Grid>
                ))
              ) : (
                <div
                  style={{
                    // flex: 1,
                    // display: "flex",

                    width: 360,
                    height: 100,
                    backgroundColor: "whitesmoke",
                    marginTop: 16,
                    marginBottom: 16,
                    borderRadius: 8,
                    alignItems: "center",
                    marginLeft:16
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Please select the main institute
                  </Typography>
                </div>
              )}
            </Grid>
          </div>
          <Typography
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
            // className={classes.formControl}
            variant="body2"
            color="primary"
          >
            {instituteError}
          </Typography>
          <div style={{ display: 'flex', justifyContent: "space-evenly" }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              style={{ margin: 8, minWidth: 170, height: 50 }}

              onClick={onClickCreateMIAdmin}
            >
              Create
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              className={classes.button}
              style={{ margin: 8, minWidth: 170, height: 50 }}

              onClick={goBack}
            >
              Cancel
            </Button>



          </div>
          <div style={{ width: 360,marginLeft:8 }}>
            {alert && (
              <Alert icon={false} severity={alert.alertType}>{alert.message}</Alert>
            )}
            {commonError && (
              <Alert icon={false} severity="error" >{commonError}</Alert>
            )}
            {!commonError && (
              <div style={{ height: 48, width: 360, }} ></div>
            )}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default CreateMIAdmin;

import React from "react";
import { makeStyles, List } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.primary,
  },
}));

const TeacherListItem = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className={classes.root}>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>
            {props.teacher.subject.name}
          </Typography>
        </AccordionSummary>
        <List>
          {props.teacher.teachers.map((tchr, i) => (
            <div key={i.toString()}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src={tchr.imageUrl} />
                </ListItemAvatar>
                <ListItemText primary={tchr.name} />
                <ListItemText primary={tchr.phoneNumber} />
                  <ListItemText primary={tchr.email} />
              </ListItem>
              {/* <Divider variant="inset" component="li" /> */}
            </div>
          ))}
        </List>
      </Accordion>
    </div>
  );
};

export default TeacherListItem;

import React, { useEffect } from "react";
import Drawer from "../components/Drawer";
//import Typography from "@material-ui/core/Typography";
import Select from "../components/Select";
import CustomTextField from "../components/CustomTextField";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import Breadcrumbs from "../components/Breadcrumbs";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { createStudent } from "../store/actions/Students";
import { getInstituteGroups } from "../store/actions/Groups";
import {
  getAllInstitutes,
  getAllInstitutesByMainInstiuteId,
} from "../store/actions/Institutes";
import { getAllMainInsitutes } from "../store/actions/MainInstitute";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const CreateStudent = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  const insts = useSelector((state) => state.institutes.institutes);
  const grps = useSelector((state) => state.groups.groups);
  const message = useSelector((state) => state.students.message);
  const error = useSelector((state) => state.students.error);
  const maininsts = useSelector((state) => state.mainInstitute.mainInstitutes);
  const alert = useSelector((state) => state.alert.alert);
  const [mainInstitute, setMainInstitute] = React.useState("");

  const [institute, setInstitute] = React.useState("");
  const [group, setGroup] = React.useState("");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const [instituteError, setInstituteError] = React.useState("");
  const [groupError, setGroupError] = React.useState("");
  const [nameError, setNameError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [confirmPasswordError, setConfirmPasswordError] = React.useState("");

  useEffect(() => {
    dispatch(getAllMainInsitutes());
  }, [dispatch]);
  const handleMainInstituteChange = (e) => {
    e.preventDefault();
    setMainInstitute([e.target.value]);
    dispatch(getAllInstitutesByMainInstiuteId(maininsts[e.target.value]._id));
  };

  const handleInstituteChange = (e) => {
    e.preventDefault();

    setInstitute([e.target.value]);
    dispatch(getInstituteGroups(insts[e.target.value]._id));
    setInstituteError("");
    setGroup("");
    setGroupError("");
  };

  const handleGroupChange = (e) => {
    e.preventDefault();
    setGroup([e.target.value]);
    setGroupError("");
  };
  const handleNameChange = (e) => {
    e.preventDefault();

    setName(e.target.value);
    setNameError("");
  };
  const handleEmailChange = (e) => {
    e.preventDefault();

    setEmail(e.target.value);
    setEmailError("");
  };

  const handlePhoneNumberChange = (e) => {
    e.preventDefault();

    setPhone(e.target.value);
    setPhoneError("");
  };
  const handlePasswordChange = (e) => {
    e.preventDefault();
    // console.log("change");
    // console.log(e.target);
    setPassword(e.target.value);
    setPasswordError("");
  };
  const handleConfirmPasswordChange = (e) => {
    e.preventDefault();
    // console.log("change");
    // console.log(e.target);
    setConfirmPassword(e.target.value);
    setConfirmPasswordError("");
  };
  const goBack = () => {
    props.history.goBack();
  };

  const validate = () => {
    if (institute === "") {
      setInstituteError("Select Institute");

      return false;
    }
    if (group === "") {
      setGroupError("Select Group");

      return false;
    }
    if (name === "") {
      setNameError("Name is required");
      return false;
    }

    if (email === "") {
      setEmailError("Email is required");
      return false;
    }
    if (email.length > 0) {
      if (!regexp.test(email)) {
        setEmailError("Invalid Email");
        return false;
      }
    }
    if (phone === "") {
      setPhoneError("Phone is required");
      return false;
    }
    if (phone.length > 0) {
      if (!phoneno.test(phone)) {
        setPhoneError("Invalid Phone Number");
        return false;
      }
    }
    if (password == "") {
      setPasswordError("Password is required");
      return false;
    }
    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      return false;
    }

    return true;
  };
  const onClickCreateStudent = () => {
    const isValid = validate();
    if (isValid) {
      const std = {
        instituteId: insts[institute]._id,
        groupId: grps[group]._id,
        name,
        email,
        phoneNumber: phone,
        password,
      };
      dispatch(createStudent(std));
      setInstitute("");
      setGroup("");
      setName("");
      setEmail("");
      setPhone("");
    }
  };

  return (
    <div>
      <Drawer>
        <Breadcrumbs />
        <div
          style={{
            alignItems: "flex-start",
            justifyContent: "flex-start",
            // alignSelf: "center",
            display: "flex",
            flexDirection: "column",
            // marginLeft: 40,
          }}
        >
          <div style={{ width: 400 }}>
           
           {alert && (
         <Alert severity={alert.alertType}>{alert.message}</Alert>
       )}
       </div>
          <Select
            options={maininsts}
            firstMenu="Select Main Institute"
            label="Main Institute"
            value={mainInstitute}
            onChange={handleMainInstituteChange}
          />
          <Select
            options={insts}
            firstMenu="Select Institute"
            label="Institutes"
            value={institute}
            onChange={handleInstituteChange}
            error={instituteError}
          />

          <Select
            options={grps}
            firstMenu="Select Group"
            label="Groups"
            value={group}
            onChange={handleGroupChange}
            error={groupError}
          />

          <CustomTextField
            label="Name"
            value={name}
            onChange={handleNameChange}
            error={nameError}
          />
          <CustomTextField
            label="Email Id"
            value={email}
            onChange={handleEmailChange}
            error={emailError}
          />
          <CustomTextField
            label="Phone Number"
            value={phone}
            onChange={handlePhoneNumberChange}
            error={phoneError}
          />
          <CustomTextField
            label="Password"
            value={password}
            onChange={handlePasswordChange}
            error={passwordError}
          />
          <CustomTextField
            label="Confirm Password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            error={confirmPasswordError}
          />

          <div style={{ marginTop: 16 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              startIcon={<CancelIcon />}
              onClick={goBack}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              startIcon={<SaveIcon />}
              onClick={onClickCreateStudent}
            >
              Add Student
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default CreateStudent;

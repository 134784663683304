import React from "react";
import Drawer from "../components/Drawer";
//import Typography from "@material-ui/core/Typography";
import CustomTextField from "../components/CustomTextField";
import Typography from "@material-ui/core/Typography";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import { makeStyles, Button, Paper, Avatar } from "@material-ui/core";
import Breadcrumbs from "../components/Breadcrumbs";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const EditGroup = () => {
  const classes = useStyles();

  const [groupName, setGroupName] = React.useState("");
  const [description, setDescription] = React.useState("");

  //subject object

  //const [imageUrl, setImageUrl] = React.useState("");
  //console.log("std" + JSON.stringify(standard));

  const handleGroupNameChange = (e) => {
    // e.preventDefault();
    console.log("grp name");
    console.log(e.target.value);
    setGroupName(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    e.preventDefault();
    //console.log("change");
    //console.log(e.target.value);
    setDescription(e.target.value);
  };

  return (
    <div>
      <Drawer>
        <Breadcrumbs />
        <div
          style={{
            alignItems: "flex-start",
            justifyContent: "flex-start",
            // alignSelf: "center",
            display: "flex",
            flexDirection: "column",
            marginLeft: 40,
          }}
        >
          <CustomTextField
            label="Group Name"
            value={groupName}
            onChange={handleGroupNameChange}
          />
          <CustomTextField
            label="Description"
            value={description}
            onChange={handleDescriptionChange}
          />

          <div>
            <Typography variant="p" component="h5">
              Group Image
            </Typography>
            <Typography
              color="textSecondary"
              variant="p"
              component="p"
              gutterBottom
            >
              Upload group profile image, it is visible to all group memebers
            </Typography>
            <Paper variant="outlined" style={{ height: 160, display: "grid" }}>
              <div
                style={{
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Avatar
                  style={{ width: 80, height: 80, margin: 8 }}
                  variant="square"
                  className={classes.square}
                  src="../assets/images/science.png"
                />
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  Change Image
                </Button>
              </div>
            </Paper>
          </div>
          <div style={{ marginTop: 16 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              startIcon={<CancelIcon />}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default EditGroup;

import React, { useEffect } from "react";
import Drawer from "../components/Drawer";
import XLSX from "xlsx";
//import Typography from "@material-ui/core/Typography";
import Select from "../components/Select";
import CustomTextField from "../components/CustomTextField";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles, Button, Typography } from "@material-ui/core";
import Breadcrumbs from "../components/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { createMultipleTeachers } from "../store/actions/Teachers";
import { getAllInstitutesByMainInstiuteId } from "../store/actions/Institutes";
import { getAllMainInsitutes } from "../store/actions/MainInstitute";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const CreateMultipleTeacher = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const insts = useSelector((state) => state.institutes.institutes);
  const message = useSelector((state) => state.teachers.message);
  const error = useSelector((state) => state.teachers.error);
  const maininsts = useSelector((state) => state.mainInstitute.mainInstitutes);
  const alert = useSelector((state) => state.alert.alert);
  const [mainInstitute, setMainInstitute] = React.useState("");

  const [institute, setInstitute] = React.useState("");
  const [teachers, setTeachers] = React.useState([]);

  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const [instituteError, setInstituteError] = React.useState("");

  const [passwordError, setPasswordError] = React.useState("");
  const [confirmPasswordError, setConfirmPasswordError] = React.useState("");

  useEffect(() => {
    dispatch(getAllMainInsitutes());
  }, [dispatch]);

  const handleMainInstituteChange = (e) => {
    e.preventDefault();
    setMainInstitute([e.target.value]);
    dispatch(getAllInstitutesByMainInstiuteId(maininsts[e.target.value]._id));
  };
  const handleInstituteChange = (e) => {
    e.preventDefault();
    //console.log("change");
    //console.log(e.target.value);
    setInstitute([e.target.value]);
    setInstituteError("");
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();
    // console.log("change");
    // console.log(e.target);
    setPassword(e.target.value);
    setPasswordError("");
  };
  const handleConfirmPasswordChange = (e) => {
    e.preventDefault();
    // console.log("change");
    // console.log(e.target);
    setConfirmPassword(e.target.value);
    setConfirmPasswordError("");
  };

  const validate = () => {
    if (institute === "") {
      setInstituteError("Institute  is Required");

      return false;
    }

    if (password == "") {
      setPasswordError("Password is required");
      return false;
    }
    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      return false;
    }

    return true;
  };
  const goBack = () => {
    props.history.goBack();
  };

  const handleFileUpload = (e) => {
    e.preventDefault();

    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws);

      let newData = dataParse.map((rec) => {
        let obj = {
          //  can create custom object
          name: rec.name,
          phoneNumber: rec.phone,
          email: rec.email,
        };
        return obj;
      });
      console.log(dataParse);

      setTeachers(newData);
    };
    reader.readAsBinaryString(f);
  };

  const onClickCreateTeacher = () => {
    const isValid = validate();
    if (isValid) {
      dispatch(
        createMultipleTeachers({
          instituteId: insts[institute]._id,
          teachers,
          password,
        })
      );
    }
  };
  return (
    <div>
      <Drawer>
        <Breadcrumbs />
        <div
          style={{
            alignItems: "flex-start",
            justifyContent: "flex-start",
            // alignSelf: "center",
            display: "flex",
            flexDirection: "column",
            // marginLeft: 40,
          }}
        >
          <div style={{ width: 400 }}>
           
           {alert && (
         <Alert severity={alert.alertType}>{alert.message}</Alert>
       )}
       </div>
          <Select
            options={maininsts}
            firstMenu="Select Main Institute"
            label="Main Institute"
            value={mainInstitute}
            onChange={handleMainInstituteChange}
          />
          <Select
            options={insts}
            firstMenu="Select Institute"
            label="Institute"
            value={institute}
            onChange={handleInstituteChange}
            error={instituteError}
          />

          <CustomTextField
            label="Password"
            value={password}
            onChange={handlePasswordChange}
            error={passwordError}
          />
          <CustomTextField
            label="Confirm Password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            error={confirmPasswordError}
          />
          <div
            style={{
              margin: 8,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <input
              type="file"
              required
              accept=".xlsx"
              onChange={handleFileUpload}
            />
            <Typography variant="body1">
              {teachers.length > 0 && teachers.length}{" "}
              {teachers.length > 0 && "Teachers"}{" "}
            </Typography>
          </div>

          <div style={{ marginTop: 16 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              startIcon={<CancelIcon />}
              onClick={goBack}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              startIcon={<SaveIcon />}
              onClick={onClickCreateTeacher}
            >
              Create Teachers
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default CreateMultipleTeacher;

import React from "react";
import CustomTextField from "./CustomTextField";
import {
  Button,
  Typography,
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';

const InstituteAddressForm = (props) => {
  return (
    <React.Fragment>
      <Typography variant="h5">Address</Typography>
      <CustomTextField
        label="Street"
        value={props.address.street}
        onChange={props.handleAddressChange}
        name="street"
        size="small"
        error={props.streetError}
      />
      <CustomTextField
        label="Area"
        value={props.address.area}
        onChange={props.handleAddressChange}
        name="area"
        size="small"
        error={props.areaError}
      />
      <CustomTextField
        label="City"
        value={props.address.city}
        onChange={props.handleAddressChange}
        name="city"
        size="small"
        error={props.cityError}
      />
      <CustomTextField
        label="Taluk"
        value={props.address.taluk}
        onChange={props.handleAddressChange}
        name="taluk"
        size="small"
        error={props.talukError}
      />
      <CustomTextField
        label="District"
        value={props.address.district}
        onChange={props.handleAddressChange}
        name="district"
        size="small"
        error={props.districtError}
      />
      <CustomTextField
        label="State"
        value={props.address.state}
        onChange={props.handleAddressChange}
        name="state"
        size="small"
        error={props.stateError}
      />
      <CustomTextField
        label="Pincode"
        value={props.address.pincode}
        onChange={props.handleAddressChange}
        name="pincode"
        size="small"
        error={props.pincodeError}
      />
      <div style={{ display: 'flex', justifyContent: "space-evenly" }}
      >  <Button
        variant="contained"
        color="primary"
        onClick={props.gotoNext}
        style={{ margin: 8, minWidth: 170, height: 50 }}

      >
          Create
        </Button>
        <Button
          variant="contained"
          onClick={props.gotoPrev}
          style={{ margin: 8, minWidth: 170, height: 50 }}

        >
          Back
        </Button>

      </div>
      <div style={{ width: 360, marginLeft: 8 }}>
        {props.alert && (
          <Alert icon={false} severity={alert.alertType}>{props.alert.message}</Alert>
        )}
        {props.commonError && (
          <Alert icon={false} severity="error" >{props.commonError}</Alert>
        )}
        {!props.commonError && (
          <div style={{ height: 48, width: 360, }} ></div>
        )}
      </div>
    </React.Fragment>
  );
};

export default InstituteAddressForm;

import {
  CREATE_GROUP_REQUEST,
  CREATE_GROUP_FAILURE,
  CREATE_GROUP_SUCCESS,
  GET_GROUP_DETAIL_REQUEST,
  GET_GROUP_DETAIL_FAILURE,
  GET_GROUP_DETAIL_SUCCESS,
  GET_INSTITUTE_GROUPS_REQUEST,
  GET_INSTITUTE_GROUPS_FAILURE,
  GET_INSTITUTE_GROUPS_SUCCESS,
  EDIT_GROUP_REQUEST,
  EDIT_GROUP_FAILURE,
  EDIT_GROUP_SUCCESS,
  GET_GROUP_SUBJECTS_SUCCESS,
  GET_GROUP_SUBJECTS_REQUEST,
  GET_GROUP_SUBJECTS_FAILURE,
  UPDATE_GROUP_STATUS_FAILURE,
  UPDATE_GROUP_STATUS_REQUEST,
  UPDATE_GROUP_STATUS_SUCCESS,
  SET_GROUP,
} from "../actions/Groups";

const initialState = {
  groups: [],
  group: null,
  groupId: null,
  subjects: [],
  isLoading: false,
  message: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_GROUP_REQUEST:
    case UPDATE_GROUP_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case CREATE_GROUP_SUCCESS:
    case UPDATE_GROUP_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
      };
    case SET_GROUP:
      return {
        ...state,
        groupId: action.payload,
      };

    case CREATE_GROUP_FAILURE:
    case UPDATE_GROUP_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case EDIT_GROUP_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case EDIT_GROUP_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GET_INSTITUTE_GROUPS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_INSTITUTE_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.payload,
        isLoading: false,
      };
    case GET_INSTITUTE_GROUPS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GET_GROUP_DETAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_GROUP_DETAIL_SUCCESS:
      return {
        ...state,
        group: action.payload,
        isLoading: false,
      };
    case GET_GROUP_DETAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GET_GROUP_SUBJECTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_GROUP_SUBJECTS_SUCCESS:
      return {
        ...state,
        subjects: action.payload,
        isLoading: false,
      };
    case GET_GROUP_SUBJECTS_FAILURE:
      return {
        ...state,
        subjects: [],
        error: action.payload,
        isLoading: false,
      };

    default:
      return state;
  }
}

import axios from "axios";
import { baseUrl } from "../../config/baseUrl";
import { setAlert } from './Alert'
export const CREATE_SUPPORT_USER_REQUEST = "CREATE_SUPPORT_USER_REQUEST";
export const CREATE_SUPPORT_USER_FAILURE = "CREATE_SUPPORT_USER_FAILURE";
export const CREATE_SUPPORT_USER_SUCCESS = "CREATE_SUPPORT_USER_SUCCESS";
export const EDIT_SUPPORT_USER_REQUEST = "EDIT_SUPPORT_USER_REQUEST";
export const EDIT_SUPPORT_USER_FAILURE = "EDIT_SUPPORT_USER_FAILURE";
export const EDIT_SUPPORT_USER_SUCCESS = "EDIT_SUPPORT_USER_SUCCESS";

export const GET_ALL_SUPPORT_USERS_REQUEST = "GET_ALL_SUPPORT_USERS_REQUEST";
export const GET_ALL_SUPPORT_USERS_FAILURE = "GET_ALL_SUPPORT_USERS_FAILURE";
export const GET_ALL_SUPPORT_USERS_SUCCESS = "GET_ALL_SUPPORT_USERS_SUCCESS";

export const GET_SUPPORT_USER_DETAIL_REQUEST = "GET_SUPPORT_USER_DETAIL_REQUEST";
export const GET_SUPPORT_USER_DETAIL_FAILURE = "GET_SUPPORT_USER_DETAIL_FAILURE";
export const GET_SUPPORT_USER_DETAIL_SUCCESS = "GET_SUPPORT_USER_DETAIL_SUCCESS";

export const createSupportUser = (userData) => async (dispatch) => {
    const { name, email, phoneNumber, isAdmin } = userData;
    dispatch({
        type: CREATE_SUPPORT_USER_REQUEST,
    });
    const config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    };
    const body = JSON.stringify({
        name,
        email,
        phoneNumber,
        isAdmin,
    });
    try {
        //console.log(body);

        const res = await axios.post(
            `${baseUrl}/api/support-user/create-new`,
            body,
            config
        );
        console.log(res);
        if (res.status === 200) {
            dispatch({
                type: CREATE_SUPPORT_USER_SUCCESS,
                payload: res.data.message,
            });
            dispatch(setAlert({ message: res.data.message, alertType: 'success' }))
        } else {
            dispatch({
                type: CREATE_SUPPORT_USER_FAILURE,
                payload: res.data.message,
            });
            dispatch(setAlert({ message: res.data.messages[0].msg, alertType: 'error' }))
        }
    } catch (err) {
        //  console.log(err.response.data);
        dispatch(setAlert({ message: err.response.data.messages[0].msg, alertType: 'error' }))
        dispatch({
            type: CREATE_SUPPORT_USER_FAILURE,
            payload: err.response.data.message,
        });
    }
};

export const getSupportUserDetail = (userId) => async (dispatch) => {
    const token = localStorage.getItem("token");
    dispatch({
        type: GET_SUPPORT_USER_DETAIL_REQUEST,
    });
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        try {
            const res = await axios.get(
                `${baseUrl}/api/support-user/${userId}`,
                config
            );
            console.log(res);
            if (res.status === 200) {
                dispatch({
                    type: GET_SUPPORT_USER_DETAIL_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: GET_SUPPORT_USER_DETAIL_FAILURE,
                    payload: res.data.message,
                });
            }
        } catch (err) {
            console.log("get support user id fail");

            dispatch({
                type: GET_SUPPORT_USER_DETAIL_FAILURE,
                payload: err.response.data.message,
            });
        }
    }
};

export const getAllSupportUsers = (mainInstituteId) => async (dispatch) => {
    const token = localStorage.getItem("token");
    dispatch({
        type: GET_ALL_SUPPORT_USERS_REQUEST,
    });
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        try {
            const res = await axios.get(`${baseUrl}/api/support-user/all`, config);
            console.log(res);
            if (res.status === 200) {
                dispatch({
                    type: GET_ALL_SUPPORT_USERS_SUCCESS,
                    payload: res.data.impruUsers,
                });
            } else {
                dispatch({
                    type: GET_ALL_SUPPORT_USERS_FAILURE,
                    payload: res.data.message,
                });
            }
        } catch (err) {
            console.log("get support users failure");

            dispatch({
                type: GET_ALL_SUPPORT_USERS_FAILURE,
                payload: err.response.data.message,
            });
        }
    }
};

export const editSupportUser = (id, userData) => async (dispatch) => {
    const { name, email, phoneNumber } = userData;
    dispatch({
        type: EDIT_SUPPORT_USER_REQUEST,
    });
    const token = localStorage.getItem("token");
    if (token) {
        try {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };
            const body = JSON.stringify({
                name,
                email,
                phoneNumber,
            });

            const res = await axios.put(`${baseUrl}/api/support-user`, body, config);

            if (res.status === 2000) {
                dispatch({
                    type: EDIT_SUPPORT_USER_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: EDIT_SUPPORT_USER_FAILURE,
                    payload: res.data.message,
                });
            }
        } catch (err) {
            dispatch({
                type: EDIT_SUPPORT_USER_FAILURE,
                payload: err.response.data.message,
            });
        }
    }
};

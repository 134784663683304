import React from "react";
import { BrowserRouter as Router, Route, Switch,Redirect } from "react-router-dom";
//import { BrowserHistory } from "history";

import "./App.css";
import PrivateRoute from "./routing/PrivateRoute";
import MainInstituteAdmins from "./pages/MainInstituteAdmins";
import SupportUsers from "./pages/SupportUsers";
import Institutes from "./pages/Institutes";
import MainInstitutes from "./pages/MainInstitutes";
import CreateInstitute from "./pages/CreateInstitute";
import CreateMainInstitute from "./pages/CreateMainInstitute";

import CreateMIAdmin from "./pages/CreateMIAdmin";
import CreateSupportUser from "./pages/CreateSupportUser";

import Groups from "./pages/Groups";
import NewGroup from "./pages/NewGroup";
import Teachers from "./pages/Teachers";
import Students from "./pages/Students";
import Account from "./pages/Account";
import SignIn from "./pages/SignIn";
import ForgotPassword from "./pages/ForgotPassword";
import VerifyAccount from "./pages/VerifyAccount";
import EditGroup from "./pages/EditGroup";
import CreateStudent from "./pages/CreateStudent";
import CreateMultipleStudents from "./pages/CreateMultipleStudents";
import CreateMultipleTeachers from "./pages/CreateMultipleTeachers";

import CreateTeacher from "./pages/CreateTeacher";
import AddTeacher from "./pages/AddTeacher";
import InstituteGroups from "./pages/InstituteGroups";
import GroupDetail from "./pages/GroupDetails";
import InstituteDetail from "./pages/InstituteDetail";
import PageNotFound from "./pages/PageNotFound";
import { Provider } from "react-redux";
import store from "./redux-store/store";
import ImpruAdmins from "./pages/ImpruAdmins";
import CreateImpruAdmin from "./pages/CreateImpruAdmin";
import InstituteContent from "./pages/InstituteContent";
const Routes = (props) => {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <PrivateRoute exact path="/Institutes" component={Institutes} />
          <PrivateRoute exact path="/Impru Admins" component={ImpruAdmins} />
          <PrivateRoute
            exact
            path="/Create Impru Admin"
            component={CreateImpruAdmin}
          />
          <PrivateRoute
            exact
            path="/Institute Admins"
            component={MainInstituteAdmins}
          />
          <PrivateRoute
            exact
            path="/Support Users"
            component={SupportUsers}
          />
          <PrivateRoute
            exact
            path="/Create Support User"
            component={CreateSupportUser}
          />
          <PrivateRoute
            exact
            path="/Create MI Admin"
            component={CreateMIAdmin}
          />
          <PrivateRoute
            exact
            path="/Create Main Institute"
            component={CreateMainInstitute}
          />
          <PrivateRoute
            exact
            path="/Create Institute"
            component={CreateInstitute}
          />
          <PrivateRoute
            exact
            path="/Institute Content"
            component={InstituteContent}
          />

          <PrivateRoute
            exact
            path="/Institute Details"
            component={InstituteDetail}
          />
          <PrivateRoute
            exact
            path="/Institute Groups"
            component={InstituteGroups}
          />
          <PrivateRoute exact path="/Groups" component={Groups} />
          <PrivateRoute exact path="/Create Group" component={NewGroup} />
          <PrivateRoute exact path="/Edit Group" component={EditGroup} />
          <PrivateRoute
            exact
            path="/Group Details"
            component={GroupDetail}
          />
          <PrivateRoute exact path="/Add Teacher" component={AddTeacher} />
          <PrivateRoute exact path="/Teachers" component={Teachers} />
          <PrivateRoute
            exact
            path="/Create Teacher"
            component={CreateTeacher}
          />
          <PrivateRoute
            exact
            path="/Create Multiple Teachers"
            component={CreateMultipleTeachers}
          />
          <PrivateRoute exact path="/Students" component={Students} />
          <PrivateRoute
            exact
            path="/Create Student"
            component={CreateStudent}
          />
          <PrivateRoute
            exact
            path="/Create Multiple Students"
            component={CreateMultipleStudents}
          />
          <PrivateRoute exact path="/Account" component={Account} />
          <Route exact path="/sign-in" component={SignIn} />
          <Route exact path="/Forgot Password" component={ForgotPassword} />
          <Route exact path="/Verify Account" component={VerifyAccount} />
          <PrivateRoute exact path="/" component={MainInstitutes} />
          {/* <PrivateRoute exact path="/Dashboard" component={MainInstitutes} /> */}
          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </Provider>
  );
};

export default Routes;

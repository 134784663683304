import axios from "axios";
import { baseUrl } from "../../config/baseUrl";
import {setAlert} from './Alert'
export const CREATE_GROUP_REQUEST = "CREATE_GROUP_REQUEST";
export const CREATE_GROUP_FAILURE = "CREATE_GROUP_FAILURE";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const EDIT_GROUP_REQUEST = "EDIT_GROUP_REQUEST";
export const EDIT_GROUP_FAILURE = "EDIT_GROUP_FAILURE";
export const EDIT_GROUP_SUCCESS = "EDIT_GROUP_SUCCESS";

export const UPDATE_GROUP_STATUS_REQUEST = "UPDATE_GROUP_STATUS_REQUEST";
export const UPDATE_GROUP_STATUS_FAILURE = "UPDATE_GROUP_STATUS_FAILURE";
export const UPDATE_GROUP_STATUS_SUCCESS = "UPDATE_GROUP_STATUS_SUCCESS";

export const GET_INSTITUTE_GROUPS_REQUEST = "GET_INSTITUTE_GROUPS_REQUEST";
export const GET_INSTITUTE_GROUPS_FAILURE = "GET_INSTITUTE_GROUPS_FAILURE";
export const GET_INSTITUTE_GROUPS_SUCCESS = "GET_INSTITUTE_GROUPS_SUCCESS";
export const GET_GROUP_DETAIL_REQUEST = "GET_GROUP_DETAIL_REQUEST";
export const GET_GROUP_DETAIL_FAILURE = "GET_GROUP_DETAIL_FAILURE";
export const GET_GROUP_DETAIL_SUCCESS = "GET_GROUP_DETAIL_SUCCESS";

export const GET_GROUP_SUBJECTS_REQUEST = "GET_GROUP_SUBJECTS_REQUEST";
export const GET_GROUP_SUBJECTS_FAILURE = "GET_GROUP_SUBJECTS_FAILURE";
export const GET_GROUP_SUBJECTS_SUCCESS = "GET_GROUP_SUBJECTS_SUCCESS";
export const SET_GROUP = "SET_GROUP";

export const createGroup = (userData) => async (dispatch) => {
  const {
    name,
    description,
    instituteId,
    boardId,
    standardId,
    courseId,
    subjectIds,
  } = userData;
  dispatch({
    type: CREATE_GROUP_REQUEST,
  });
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({
        name,
        description,
        instituteId,
        boardId,
        standardId,
        courseId,
        subjectIds,
      });

      const res = await axios.post(`${baseUrl}/api/group/create`, body, config);
      if (res.status === 200) {
        console.log(res);
        dispatch({
          type: CREATE_GROUP_SUCCESS,
          payload: res.data.message,
        });
      } else {
        dispatch({
          type: CREATE_GROUP_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      dispatch(setAlert({message:err.response.data.messages[0].msg,alertType:'error'}))
      dispatch({
        type: CREATE_GROUP_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

export const setGroup = (groupId) => async (dispatch) => {
  dispatch({
    type: SET_GROUP,
    payload: groupId,
  });
};
export const getGroupDetail = (groupId) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_GROUP_DETAIL_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.get(`${baseUrl}/api/group/${groupId}`, config);
      //  console.log(res);
      if (res.status == 200) {
        dispatch({
          type: GET_GROUP_DETAIL_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_GROUP_DETAIL_FAILURE,
          payload: res.data.error,
        });
      }
    } catch (err) {
      console.log("get group by id fail");
      console.log(err);
      dispatch({
        type: GET_GROUP_DETAIL_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

export const getGroupSubjects = (groupId) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_GROUP_SUBJECTS_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    try {
      const res = await axios.get(
        `${baseUrl}/api/group/subjects/${groupId}`,
        config
      );
      console.log(res);

      if (res.status === 200) {
        dispatch({
          type: GET_GROUP_SUBJECTS_SUCCESS,
          payload: res.data.subjects,
        });
      } else {
        dispatch({
          type: GET_GROUP_SUBJECTS_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      console.log("get group SUBJECTS by ID fail");
      dispatch({
        type: GET_GROUP_SUBJECTS_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

export const getInstituteGroups = (instituteId) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_INSTITUTE_GROUPS_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    try {
      const res = await axios.get(
        `${baseUrl}/api/group/institute/${instituteId}`,
        config
      );
      console.log(res.data);
      if (res.status === 200) {
        dispatch({
          type: GET_INSTITUTE_GROUPS_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_INSTITUTE_GROUPS_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      console.log("get groups failure");

      dispatch({
        type: GET_INSTITUTE_GROUPS_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};
export const editGroup = (id, userData) => async (dispatch) => {
  const { name, description } = userData;
  dispatch({
    type: EDIT_GROUP_REQUEST,
  });
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };
      const body = JSON.stringify({
        id,
        name,
        description,
      });

      const res = await axios.put(`${baseUrl}/api/group/`, body, config);
      if (res.status === 200) {
        dispatch({
          type: EDIT_GROUP_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: EDIT_GROUP_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_GROUP_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};
export const updateGroupStatus = ({ groupId, status }) => async (dispatch) => {
  dispatch({
    type: UPDATE_GROUP_STATUS_REQUEST,
  });
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };
    //  console.log(groupId, status);
      const res = await axios.put(
        `${baseUrl}/api/group/active/${groupId}/${status}`,
        config
      );
      if (res.status === 200) {
        dispatch({
          type: UPDATE_GROUP_STATUS_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: UPDATE_GROUP_STATUS_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      dispatch({
        type: UPDATE_GROUP_STATUS_FAILURE,
        payload: "Error",
      });
    }
  }
};

import axios from "axios";
import { baseUrl } from "../../config/baseUrl";
import {setAlert} from './Alert'
export const GET_CONTENT_PROVIDER_REQUEST = "GET_CONTENT_PROVIDER_REQUEST";
export const GET_CONTENT_PROVIDER_FAILURE = "GET_CONTENT_PROVIDER_FAILURE";
export const GET_CONTENT_PROVIDER_SUCCESS = "GET_CONTENT_PROVIDER_SUCCESS";

export const GET_BOARDS_BY_CONTENT_PROVIDER_REQUEST =
  "GET_BOARDS_BY_CONTENT_PROVIDER_REQUEST";
export const GET_BOARDS_BY_CONTENT_PROVIDER_FAILURE =
  "GET_BOARDS_BY_CONTENT_PROVIDER_FAILURE";
export const GET_BOARDS_BY_CONTENT_PROVIDER_SUCCESS =
  "GET_BOARDS_BY_CONTENT_PROVIDER_SUCCESS";

export const GET_STANDARDS_BY_BOARD_REQUEST = "GET_STANDARDS_BY_BOARD_REQUEST";
export const GET_STANDARDS_BY_BOARD_FAILURE = "GET_STANDARDS_BY_BOARD_FAILURE";
export const GET_STANDARDS_BY_BOARD_SUCCESS = "GET_STANDARDS_BY_BOARD_SUCCESS";

export const GET_COURSES_BY_STANDARD_REQUEST =
  "GET_COURSES_BY_STANDARD_REQUEST";
export const GET_COURSES_BY_STANDARD_FAILURE =
  "GET_COURSES_BY_STANDARD_FAILURE";
export const GET_COURSES_BY_STANDARD_SUCCESS =
  "GET_COURSES_BY_STANDARD_SUCCESS";

export const GET_SUBJECTS_BY_COURSE_REQUEST = "GET_SUBJECTS_BY_COURSE_REQUEST";
export const GET_SUBJECTS_BY_COURSE_FAILURE = "GET_SUBJECTS_BY_COURSE_FAILURE";
export const GET_SUBJECTS_BY_COURSE_SUCCESS = "GET_SUBJECTS_BY_COURSE_SUCCESS";

export const getAllContentProviders = () => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_CONTENT_PROVIDER_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.get(
        `${baseUrl}/api/content/content-provider`,
        config
      );
      //  console.log("conent providers" + JSON.stringify(res));
      if (res.status == 200) {
        dispatch({
          type: GET_CONTENT_PROVIDER_SUCCESS,
          payload: res.data.contentProviders,
        });
      } else {
        dispatch({
          type: GET_CONTENT_PROVIDER_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_CONTENT_PROVIDER_FAILURE,
        payload: err.response.message,
      });
    }
  }
};

export const getBoardsByContentProvider = ({ contentProviderId }) => async (
  dispatch
) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_BOARDS_BY_CONTENT_PROVIDER_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.get(
        `${baseUrl}/api/content/boards/${contentProviderId}`,
        config
      );
      // console.log("inst detail" + res);
      if (res.status === 200) {
        dispatch({
          type: GET_BOARDS_BY_CONTENT_PROVIDER_SUCCESS,
          payload: res.data.boards,
        });
      } else {
        dispatch({
          type: GET_BOARDS_BY_CONTENT_PROVIDER_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_BOARDS_BY_CONTENT_PROVIDER_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

export const getStandardsByBoardId = ({ boardId }) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_STANDARDS_BY_BOARD_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.get(
        `${baseUrl}/api/content/standards/${boardId}`,

        config
      );
      console.log("standards res" + JSON.stringify(res.data));
      if (res.status === 200) {
        dispatch({
          type: GET_STANDARDS_BY_BOARD_SUCCESS,
          payload: res.data.standards,
        });
      } else {
        dispatch({
          type: GET_STANDARDS_BY_BOARD_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_STANDARDS_BY_BOARD_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

export const getCoursesByStandardId = ({ standardId }) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_COURSES_BY_STANDARD_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.get(
        `${baseUrl}/api/content/courses/${standardId}`,

        config
      );
      //console.log("courses res" + JSON.stringify(res));
      if (res.status === 200) {
        dispatch({
          type: GET_COURSES_BY_STANDARD_SUCCESS,
          payload: res.data.courses,
        });
      } else {
        dispatch({
          type: GET_COURSES_BY_STANDARD_SUCCESS,
          payload: res.data.message,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_COURSES_BY_STANDARD_SUCCESS,
        payload: err.response.data.message,
      });
    }
  }
};

export const getSubjectsByCourseId = ({ courseId }) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_SUBJECTS_BY_COURSE_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.get(
        `${baseUrl}/api/content/subjects/${courseId}`,

        config
      );
      // console.log("inst detail" + res);
      if (res.status === 200) {
        dispatch({
          type: GET_SUBJECTS_BY_COURSE_SUCCESS,
          payload: res.data.subjects,
        });
      } else {
        dispatch({
          type: GET_SUBJECTS_BY_COURSE_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_SUBJECTS_BY_COURSE_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

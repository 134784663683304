import React from "react";
import CustomTextField from "./CustomTextField";
import {
  makeStyles,
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import Select from "./Select";
const useStyles = makeStyles((theme) => ({
  multiText: {
    "& > *": {
      margin: theme.spacing(1),
      width: "400px",
    },
  },
}));

const InstituteDetailForm = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <CustomTextField
        label="Institute Name"
        value={props.name}
        onChange={props.handleNameChange}
        error={props.nameError}
      />

      <form className={classes.multiText} noValidate autoComplete="off">
        <TextField
          label="Description"
          variant="outlined"
          color="primary"
          multiline
          rows={4}
          value={props.description}
          onChange={props.handleDescriptionChange}
          style={{ width: 360 }}
        />
      </form>
      <Select
        options={props.instituteModes}
        firstMenu="Select Institute Mode"
        label="Institute Mode"
        value={props.instituteMode}
        onChange={props.handleInstituteModeChange}
        error={props.instituteModeError}
      />
      <Select
        options={props.instituteTypes}
        firstMenu="Select Institute Category"
        label="Institute Category"
        value={props.instituteType}
        onChange={props.handleInstituteTypeChange}
        error={props.instituteTypeError}
      />
      <div style={{ margin: 8 }}>
        <FormControl>
          <FormLabel>Payment By</FormLabel>
          <RadioGroup
            name="PAYMENT"
            value={props.pay}
            onChange={props.handlePayChange}
          >
            <FormControlLabel
              value="INSTITUTE"
              control={<Radio />}
              label="Institute"
            />
            <FormControlLabel
              value="STUDENT"
              control={<Radio />}
              label="Student"
            />
          </RadioGroup>
        </FormControl>
      </div>

      <div
        style={{ display: 'flex', justifyContent: "space-evenly" }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={props.gotoNext}
          style={{ margin: 8, minWidth: 170, height: 50 }}
        >
          Continue
        </Button>
        <Button
          variant="contained"
          onClick={props.goBack}
          color="secondary"
          style={{ margin: 8, minWidth: 170, height: 50 }}
        >
          Cancel
        </Button>

      </div>
      <div style={{ width: 360, marginLeft: 8 }}>
        {props.alert && (
          <Alert icon={false} severity={alert.alertType}>{props.alert.message}</Alert>
        )}
        {props.commonError && (
          <Alert icon={false} severity="error" >{props.commonError}</Alert>
        )}
        {!props.commonError && (
          <div style={{ height: 48, width: 360, }} ></div>
        )}
      </div>
    </React.Fragment>
  );
};

export default InstituteDetailForm;

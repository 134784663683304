import React, { useEffect } from "react";
import Drawer from "../components/Drawer";
import InstituteDetail from "../components/InstituteDetail";

import {} from "@material-ui/core";
import Breadcrumbs from "../components/Breadcrumbs";
import { useSelector } from "react-redux";

const InstituteDetailPage = (props) => {
  const institute = useSelector((state) => state.institutes.institute);
  const isLoading = useSelector((state) => state.institutes.isLoading);

  useEffect(() => {
    if (institute == null && isLoading === false) {
      props.history.push("/Dashboard");
    }
  }, [institute, props.history, isLoading]);
  return (
    <div>
      <Drawer>
        <Breadcrumbs />
        <div
          style={{
            //alignContent: "center",
            //alignItems: "center",
            justifyContent: "center",
            // alignSelf: "center",
            // display: "flex",
          }}
        >
          <div>
            <InstituteDetail institute={institute !== null && institute} />
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default InstituteDetailPage;

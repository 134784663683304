import React from "react";
import CustomTextField from "./CustomTextField";
import { Button, Typography } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
const InstituteContactInfoForm = (props) => {
  return (
    <React.Fragment>
      <Typography variant="h5">Contact Info</Typography>
      <CustomTextField
        label="Phone Number"
        value={props.contactInfo.phoneNumber}
        onChange={props.handleContactInfoChange}
        name="phoneNumber"
        size="small"
        error={props.phoneNumberError}
      />
      <CustomTextField
        label="Email"
        value={props.contactInfo.email}
        onChange={props.handleContactInfoChange}
        name="email"
        size="small"
        error={props.emailError}
      />
      <div
        style={{ display: 'flex', justifyContent: "space-evenly" }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={props.gotoNext}
          style={{ margin: 8, minWidth: 170, height: 50 }}

        >
          Continue
        </Button>
        <Button
          variant="contained"
          onClick={props.gotoPrev}
          style={{ margin: 8, minWidth: 170, height: 50 }}
        >
          Back
        </Button>

      </div>
      <div style={{ width: 360, marginLeft: 8 }}>
        {props.alert && (
          <Alert icon={false} severity={alert.alertType}>{props.alert.message}</Alert>
        )}
        {props.commonError && (
          <Alert icon={false} severity="error" >{props.commonError}</Alert>
        )}
        {!props.commonError && (
          <div style={{ height: 48, width: 360, }} ></div>
        )}
      </div>
    </React.Fragment>
  );
};

export default InstituteContactInfoForm;

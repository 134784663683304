import React, { useEffect } from "react";
import Drawer from "../components/Drawer";
import moment from "moment";
import { makeStyles, Tooltip, Fab, Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import Theme from "../Theme";
import Select from "../components/Select";
import AddIcon from "@material-ui/icons/Add";
import Breadcrumbs from "../components/Breadcrumbs";
import { getAllInstitutesByMainInstiuteId } from "../store/actions/Institutes";
import { getAllMainInstituteAllAdmins } from "../store/actions/MainInstituteAdmins";
import { useSelector, useDispatch } from "react-redux";
import { getAllMainInsitutes } from "../store/actions/MainInstitute";
const useStyles = makeStyles((theme) => ({
  table: {
    "& tbody>.MuiTableRow-root:hover": {
      background: "whitesmoke",
    },
  },
}));

const MainInstituteAdmins = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [institute, setInstitute] = React.useState("");
  const [mainInstitute, setMainInstitute] = React.useState("");
  const mainInsts = useSelector((state) => state.mainInstitute.mainInstitutes);
  const insts = useSelector((state) => state.institutes.institutes);
  const mainInstAdmins = useSelector(
    (state) => state.mainInstituteAdmins.mainInstituteAdmins
  );

  const handleMainInstituteChange = (e) => {
    e.preventDefault();
    setMainInstitute([e.target.value]);

    dispatch(getAllInstitutesByMainInstiuteId(mainInsts[e.target.value]._id));
  };
  const handleInstituteChange = (e) => {
    e.preventDefault();
    setInstitute([e.target.value]);

    dispatch(getAllMainInstituteAllAdmins(insts[e.target.value]._id));
  };
  useEffect(() => {
    dispatch(getAllMainInsitutes());
  }, [dispatch]);

  const col = [
    {
      field: "name",
      title: "Name",
      cellStyle: {
        width: 150,
        maxWidth: 200,
      },
      headerStyle: {
        width: 150,
        maxWidth: 200,
      },
    },
    {
      field: "email",
      title: "Email",
      cellStyle: {
        width: 150,
        maxWidth: 200,
      },
      headerStyle: {
        width: 150,
        maxWidth: 200,
      },
    },
    { field: "phoneNumber", title: "Phone #", width: 150 },
    // { field: "isVerified", title: "Account Verified?", width: 130 },
    // { field: "role", title: "Role", width: 100 },
    {
      field: "createdAt",
      title: "Created on",
      width: 130,
      render: (rowData) => <div>{moment(rowData.createdAt).format('DD/MM/YYYY')}</div>,
    },
  ];
  return (
    <div>
      <Drawer>
        <Breadcrumbs />
        <div
          style={{
            //width: 800,
            alignItems: "center",
            //justifyContent: "center",
            //alignSelf: "center",
            //alignContent: "center",
            //flex: 1,
            // display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ flexDirection: "row", display: "flex" }}>
          <div style={{ width: 400 }}>
            <Select
              options={mainInsts}
              // firstMenu="Select Main Institute"
              label=" Main Institute"
              value={mainInstitute}
              onChange={handleMainInstituteChange}
            />
          </div>
          <div style={{ width: 400 }}>
            <Select
              options={insts}
              // firstMenu="Select Institute"
              label="Institute"
              value={institute}
              onChange={handleInstituteChange}
            />
          </div>
          </div>
          <div
            style={{
              width: 800,
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "center",
              //flex: 1,
              //display: "flex",
            }}
          >
            <div className={classes.table} style={{ maxWidth: "100%" }}>
              <MaterialTable
                theme={Theme}
                columns={col}
                data={mainInstAdmins}
                title="Institute Admins"
                options={{
                  exportButton: true,
                 // selection: true,
                }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            bottom: 0,
            right: 0,
            marginBottom: 16,
            marginRight: 16,
            position: "fixed",
          }}
        >
          <Tooltip title="New Institute Admin" aria-label="add">
            <Fab
              color="primary"
              aria-label="add"
              onClick={() => {
                props.history.push("/Create MI Admin");
              }}
            >
              <AddIcon style={{ color: "white" }} />
            </Fab>
          </Tooltip>
        </div>
      </Drawer>
    </div>
  );
};

export default MainInstituteAdmins;

//import isEmpty from "../../validation/is-empty";
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  USER_LOADED,
  AUTH_ERROR,
  LOAD_USER_FAILURE,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
} from "../actions/Auth";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: false,
  user: null,
  isLoading: false,
  error: null,
  messages: null,
  errors: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOAD_USER_REQUEST:
      //localStorage.getItem("token", action.payload.token);
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_USER_SUCCESS:
      //localStorage.getItem("token", action.payload.token);
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        isLoading: false,
      };

    case LOGIN_SUCCESS:
      localStorage.getItem("token", action.payload.token);
      return {
        ...state,
        //...action.payload,
        isAuthenticated: true, //!isEmpty(action.payload.token),
        user: action.payload.user,
        isLoading: false,
        // message: action.payload.message,
      };

    case LOGOUT:
    case LOAD_USER_FAILURE:
      localStorage.removeItem("token");
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        isLoading: false,
        messages: action.payload,
      };

    case LOGIN_FAILURE:
      localStorage.removeItem("token");
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}

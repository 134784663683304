import axios from "axios";
import { baseUrl } from "../../config/baseUrl";
import {setAlert} from './Alert'
export const GET_MAIN_INSTITUTE_REQUEST = "GET_MAIN_INSTITUTE_REQUEST";
export const GET_MAIN_INSTITUTE_FAILURE = "GET_MAIN_INSTITUTE_FAILURE";
export const GET_MAIN_INSTITUTE_SUCCESS = "GET_MAIN_INSTITUTE_SUCCESS";

export const CREATE_MAIN_INSTITUTE_REQUEST = "CREATE_MAIN_INSTITUTE_REQUEST";
export const CREATE_MAIN_INSTITUTE_FAILURE = "CREATE_MAIN_INSTITUTE_FAILURE";
export const CREATE_MAIN_INSTITUTE_SUCCESS = "CREATE_MAIN_INSTITUTE_SUCCESS";

export const GET_ALL_MAIN_INSTITUTES_REQUEST =
  "GET_ALL_MAIN_INSTITUTES_REQUEST";
export const GET_ALL_MAIN_INSTITUTES_FAILURE =
  "GET_ALL_MAIN_INSTITUTES_FAILURE";
export const GET_ALL_MAIN_INSTITUTES_SUCCESS =
  "GET_ALL_MAIN_INSTITUTES_SUCCESS";

export const createMainInstitute = (userData) => async (dispatch) => {
  const {
    name,
    description,
    email,
    phoneNumber,
    address,
    isPayee,
    instituteMode,
    instituteType,
  } = userData;
  dispatch({
    type: CREATE_MAIN_INSTITUTE_REQUEST,
  });
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({
        name,
        description,
        email,
        phoneNumber,
        address,
        isPayee,
        instituteMode,
        instituteType,
      });

      const res = await axios.post(
        `${baseUrl}/api/institute/main/create`,
        body,
        config
      );
      if (res.status === 200) {
        console.log(res);
        dispatch({
          type: CREATE_MAIN_INSTITUTE_SUCCESS,
          payload: res.data.message,
        });
        dispatch(setAlert({message:res.data.message,alertType:'success'}))
      } else {
        dispatch({
          type: CREATE_MAIN_INSTITUTE_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      dispatch(setAlert({message:err.response.data.messages[0].msg,alertType:'error'}))
      dispatch({
        type: CREATE_MAIN_INSTITUTE_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

export const getAllMainInsitutes = () => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_ALL_MAIN_INSTITUTES_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    try {
      const res = await axios.get(`${baseUrl}/api/institute/main-institutes`, config);
      console.log(res);
      if (res.status === 200) {
        dispatch({
          type: GET_ALL_MAIN_INSTITUTES_SUCCESS,
          payload: res.data.mainInstitutes,
        });
      } else {
        dispatch({
          type: GET_ALL_MAIN_INSTITUTES_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      console.log("get account by id fail" + err);

      dispatch({
        type: GET_ALL_MAIN_INSTITUTES_FAILURE,
        payload: err.Error,
      });
    }
  }
};

export const getMainInsitute = () => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_MAIN_INSTITUTE_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    try {
      const res = await axios.get(`${baseUrl}/api/main-institute`, config);
      console.log(res);
      if (res.status === 200) {
        dispatch({
          type: GET_MAIN_INSTITUTE_SUCCESS,
          payload: res.data.organization,
        });
      } else {
        dispatch({
          type: GET_MAIN_INSTITUTE_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      console.log("get account by id fail");

      dispatch({
        type: GET_MAIN_INSTITUTE_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

import { combineReducers } from "redux";
import authReducer from "../store/reducers/Auth";
import Account from "../store/reducers/Account";
import Groups from "../store/reducers/Groups";
import Institutes from "../store/reducers/Institutes";
import MainInstitute from "../store/reducers/MainInstitute";
import ImpruAdmins from "../store/reducers/ImpruAdmins";
import MainInstituteAdmins from "../store/reducers/MainInstituteAdmins";
import SupportUsers from "../store/reducers/SupportUsers";
import Content from "../store/reducers/Content";
import InstituteContent from "../store/reducers/InstituteContent";

import Students from "../store/reducers/Students";
import Teachers from "../store/reducers/Teachers";
import Alert from "../store/reducers/Alert";

export default combineReducers({
  auth: authReducer,
  account: Account,
  groups: Groups,
  institutes: Institutes,
  content: Content,
  instituteContent: InstituteContent,
  mainInstitute: MainInstitute,
  impruAdmins: ImpruAdmins,
  mainInstituteAdmins: MainInstituteAdmins,
  supportUsers:SupportUsers,
  students: Students,
  teachers: Teachers,
  alert:Alert
});

import React, { useEffect, useState } from "react";
import Drawer from "../components/Drawer";
import Breadcrumbs from "../components/Breadcrumbs";
import { Alert } from "@material-ui/lab";
import InstituteAddressForm from "../components/InstituteAddressForm";
import InstituteDetailForm from "../components/MainInstituteDetailForm";
import InstituteContactInfoForm from "../components/InstituteContactInfoForm";
import ConfirmForm from "../components/ConfirmForm";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import { createMainInstitute } from "../store/actions/MainInstitute";

import { getAllMainInsitutes } from "../store/actions/MainInstitute";

const CreateInstitute = (props) => {
  const dispatch = useDispatch();
  const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  const mainInsts = useSelector((state) => state.mainInstitute.mainInstitutes);
  const alert = useSelector((state) => state.alert.alert);
  const instituteModes = [
    { name: "PRIVATE", _id: "PRIVATE" },
    { name: "AIDED", _id: "AIDED" },
    { name: "PUBLIC", _id: "PUBLIC" },
  ];

  const instituteTypes = [
    { name: "SCHOOL", _id: "SCHOOL" },
    { name: "COLLEGE", _id: "COLLEGE" },
    { name: "TUTORIAL", _id: "TUTORIAL" },
  ];
  const [step, setStep] = useState(1);
  const [mainInstitute, setMainInstitute] = useState("");
  const [instituteMode, setInstituteMode] = useState("");
  const [instituteType, setInstituteType] = useState("");
  const [name, setName] = useState("");
  const [pay, setPay] = React.useState("INSTITUTE");
  const [description, setDescription] = useState("");

  const [contactInfo, setContactInfo] = useState({
    phoneNumber: "",
    email: "",
  });

  const [boardsInputList, setBoardsInputList] = useState([{ boardName: "" }]);

  const [address, setAddress] = useState({
    street: "",
    area: "",
    city: "",
    taluk: "",
    district: "",
    state: "",
    pincode: "",
  });

  // errror states
  const [instError, setInstError] = useState("");
  const [instituteModeError, setInstituteModeError] = useState("");
  const [instituteTypeError, setInstituteTypeError] = useState("");
  const [nameError, setnameError] = useState("");

  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [emailError, setEmailError] = useState("");

  const [streetError, setStreetError] = useState("");
  const [areaError, setAreaError] = useState("");
  const [cityError, setCityError] = useState("");
  const [talukError, settalukError] = useState("");
  const [districtError, setDistrictError] = useState("");
  const [stateError, setStateError] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [commonError, setCommonError] = React.useState("");

  useEffect(() => {
    dispatch(getAllMainInsitutes());
  }, [dispatch]);

  const gotoContactInfo = () => {
    const instValid = instituteValidation();
    if (instValid) {
      setStep(step + 1);
    }
  };


  const handlePayChange = (e) => {
    setPay(e.target.value);
  };

  const gotoAddress = () => {
    const instValid = contactValidation();
    if (instValid) {
      setStep(step + 1);
    }
  };


  const gotoPrev = () => {
    setStep(step - 1);
  };

  const handleMainInstituteChange = (e) => {
    e.preventDefault();
    if (e.target.value !== "") {
      setMainInstitute([e.target.value]);
      setInstError("");
      setnameError();
      setCommonError("")

    }
  };

  const handleInstituteModeChange = (e) => {
    e.preventDefault();
    if (e.target.value !== "") {
      console.log('mode ' + e.target.value);
      setInstituteMode([e.target.value]);
      setInstituteModeError("");
      setCommonError("")

    }
    //console.log(e.target.value);
  };
  const handleInstituteTypeChange = (e) => {
    e.preventDefault();
    if (e.target.value !== "") {
      setInstituteType([e.target.value]);
      setInstituteTypeError("");
      setCommonError("")

    }
  };
  const handleNameChange = (e) => {
    e.preventDefault();

    setName(e.target.value);
    setnameError("");
    setCommonError("")

  };
  const handleDescriptionChange = (e) => {
    e.preventDefault();
    setDescription(e.target.value);
  };
  const instituteValidation = () => {

    if (name === "") {
      setnameError("Institute Name is Required");
      setCommonError("Institute Name is Required");
      return false;
    }
    if (name.length < 8) {
      setnameError("Institute Name is too short");
      setCommonError("Institute Name is too short");
      return false;
    }
    if (instituteMode === "") {
      setInstituteModeError("Institute Mode is too short");
      setCommonError("Institute Mode is too short");
      return false;
    }
    if (instituteType === "") {
      setInstituteTypeError("Institute Category is too short");
      setCommonError("Institute Category is too short");
      return false;
    }

    return true;
  };
  const contactValidation = () => {
    if (contactInfo.phoneNumber === "") {
      setPhoneNumberError("Phone Number is required");
      setCommonError("Phone Number is required");
      return false;
    }

    if (contactInfo.phoneNumber.length > 0) {
      if (!phoneno.test(contactInfo.phoneNumber)) {
        setPhoneNumberError("Invalid Phone Number");
        setCommonError("Invalid Phone Number");
        return false;
      }
    }
    if (contactInfo.email === "") {
      setEmailError("Email is Required");
      setCommonError("Email is Required");
      return false;
    }

    if (contactInfo.email.length > 0) {
      if (!regexp.test(contactInfo.email)) {
        setEmailError("Invalid Email");
        setCommonError("Invalid Email");
        return false;
      }
    }
    return true;
  };
  const addressValidation = () => {
    if (address.street === "") {
      setStreetError("Street is required");
      setCommonError("Street is required");
      return false;
    }

    if (address.area === "") {
      setAreaError("Area is required");
      setCommonError("Area is required");
      return false;
    }
    if (address.city === "") {
      setCityError("City is required");
      setCommonError("City is required");
      return false;
    }
    if (address.taluk === "") {
      settalukError("taluk is required");
      setCommonError("taluk is required");
      return false;
    }
    if (address.district === "") {
      setDistrictError("District is required");
      setCommonError("District is required");
      return false;
    }

    if (address.state === "") {
      setStateError("State is required");
      setCommonError("State is required");
      return false;
    }
    if (address.pincode === "") {
      setPincodeError("Pincode is required");
      setCommonError("Pincode is required");
      return false;
    }

    return true;
  };

  const handleContactInfoChange = (e) => {
    e.preventDefault();
    setContactInfo({ ...contactInfo, [e.target.name]: e.target.value });
    if (e.target.name === "phoneNumber") {
      setPhoneNumberError("");
      setCommonError("")
    }
    if (e.target.name === "email") {
      setEmailError("");
      setCommonError("")
    }
  };

  const handleAddressChange = (e) => {
    e.preventDefault();
    setAddress({ ...address, [e.target.name]: e.target.value });
    if (e.target.name === "street") {
      setStreetError("");
      setCommonError("")

      return true
    }
    if (e.target.name === "area") {
      setAreaError("");
      setCommonError("")

      return true
    }

    if (e.target.name === "city") {
      setCityError("");
      setCommonError("")

      return true
    }
    if (e.target.name === "taluk") {
      settalukError("");
      setCommonError("")

      return true
    }
    if (e.target.name === "district") {
      setDistrictError("");
      setCommonError("")

      return true
    }
    if (e.target.name === "state") {
      setStateError("");
      setCommonError("")

      return true
    }
    if (e.target.name === "pincode") {
      setPincodeError("");
      setCommonError("")

      return true
    }

    // address error
    if (address.street === "") {
      setStreetError("Street is Required");
      setCommonError("Street is Required");
      return false;
    }
    if (address.area === "") {
      setAreaError("Area is Required");
      setCommonError("Area is Required");
      return false;
    }
    if (address.city === "") {
      setCityError("City is Required");
      setCommonError("City is Required");
      return false;
    }
    if (address.taluk === "") {
      settalukError("taluk is Required");
      setCommonError("taluk is Required");
      return false;
    }
    if (address.district === "") {
      setDistrictError("District is Required");
      setCommonError("District is Required");
      return false;
    }
    if (address.state === "") {
      setStateError("State is Required");
      setCommonError("State is Required");
      return false;
    }
    if (address.pincode === "") {
      setPincodeError("Pincode is Required");
      setCommonError("Pincode is Required");
      return false;
    }
  };

  const goBack = () => {
    props.history.goBack();
  };

  const onClickContinue = () => {
    //console.log(JSON.stringify(instData));
    setStep(step + 1);
  };
  const onClickCreateNewInstitute = () => {
    const isAddressValid = addressValidation()
    if (!isAddressValid) {
      return
    }
    const instData = {
      instituteMode: instituteModes[instituteMode]._id,
      instituteType: instituteTypes[instituteType]._id,
      isPayee: pay === "INSTITUTE" ? true : false,
      name: name,
      description,
      phoneNumber: contactInfo.phoneNumber,
      email: contactInfo.email,
      address,
    };
    dispatch(createMainInstitute(instData));
  };
  const step1 = (
    <div>
      <Drawer>
        <Breadcrumbs />
        <div
          style={{
            alignItems: "flex-start",
            justifyContent: "flex-start",
            // alignSelf: "center",
            display: "flex",
            flexDirection: "column",
            //marginLeft: 40,
          }}
        >
          <InstituteDetailForm
            mainInstitute={mainInstitute}
            instituteModes={instituteModes}
            instituteTypes={instituteTypes}
            pay={pay}
            handlePayChange={handlePayChange}
            description={description}
            name={name}
            instError={instError}
            nameError={nameError}
            instituteMode={instituteMode}
            instituteType={instituteType}
            instituteModeError={instituteModeError}
            instituteTypeError={instituteTypeError}
            handleInstituteModeChange={handleInstituteModeChange}
            handleInstituteTypeChange={handleInstituteTypeChange}
            handleMainInstituteChange={handleMainInstituteChange}
            handleNameChange={handleNameChange}
            handleDescriptionChange={handleDescriptionChange}
            gotoNext={gotoContactInfo}
            goBack={goBack}
            commonError={commonError}
            alert={alert}

          />
        </div>
      </Drawer>
    </div>
  );
  switch (step) {
    case 1:
      return step1;
    case 2:
      return (
        <div>
          <Drawer>
            <Breadcrumbs />
            <div
              style={{
                alignItems: "flex-start",
                justifyContent: "flex-start",
                // alignSelf: "center",
                display: "flex",
                flexDirection: "column",
                //marginLeft: 40,
              }}
            >
              <InstituteContactInfoForm
                contactInfo={contactInfo}
                handleContactInfoChange={handleContactInfoChange}
                phoneNumberError={phoneNumberError}
                emailError={emailError}
                gotoPrev={gotoPrev}
                gotoNext={gotoAddress}
                commonError={commonError}
                alert={alert}


              />
            </div>
          </Drawer>
        </div>
      );
    case 3:
      return (
        <div>
          <Drawer>
            <Breadcrumbs />
            <div
              style={{
                alignItems: "flex-start",
                justifyContent: "flex-start",
                // alignSelf: "center",
                display: "flex",
                flexDirection: "column",
                //marginLeft: 40,
              }}
            >
              {/* <div style={{ width: 400 }}>

                {alert && (
                  <Alert severity={alert.alertType}>{alert.message}</Alert>
                )}
              </div> */}
              <InstituteAddressForm
                address={address}
                handleAddressChange={handleAddressChange}
                streetError={streetError}
                areaError={areaError}
                cityError={cityError}
                talukError={talukError}
                districtError={districtError}
                stateError={stateError}
                pincodeError={pincodeError}
                gotoPrev={gotoPrev}
                gotoNext={onClickCreateNewInstitute}
                commonError={commonError}
                alert={alert}

              />
            </div>
          </Drawer>
        </div>
      );
    default:
      return step1;
  }
};

export default CreateInstitute;

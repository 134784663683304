import React, { useEffect } from "react";
import Drawer from "../components/Drawer";
import moment from "moment";
import { makeStyles, Tooltip, Fab, Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import Theme from "../Theme";
import Select from "../components/Select";
import AddIcon from "@material-ui/icons/Add";
import Breadcrumbs from "../components/Breadcrumbs";
import { getAllInstitutesByMainInstiuteId } from "../store/actions/Institutes";
import { getAllMainInstituteAllAdmins } from "../store/actions/ImpruAdmins";
import { useSelector, useDispatch } from "react-redux";
import { getAllImpruAdmins } from "../store/actions/ImpruAdmins";
import { getAllSupportUsers } from '../store/actions/SupportUsers'

const useStyles = makeStyles((theme) => ({
    table: {
        "& tbody>.MuiTableRow-root:hover": {
            background: "whitesmoke",
        },
    },
}));

const ImpruAdmins = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const impruAdmins = useSelector((state) => state.supportUsers.supportUsers);

    useEffect(() => {
        dispatch(getAllSupportUsers());
    }, [dispatch]);

    const col = [
        {
            field: "name",
            title: "Name",
            cellStyle: {
                width: 150,
                maxWidth: 200,
            },
            headerStyle: {
                width: 150,
                maxWidth: 200,
            },
        },
        {
            field: "email",
            title: "Email",
            cellStyle: {
                width: 150,
                maxWidth: 200,
            },
            headerStyle: {
                width: 150,
                maxWidth: 200,
            },
        },
        { field: "phoneNumber", title: "Phone #", width: 150 },
        // { field: "isVerified", title: "Account Verified?", width: 60 },
        // { field: "role", title: "Role", width: 100 },
        {
            field: "createdAt",
            title: "Created on",
            width: 130,
            render: (rowData) => <div>{moment(rowData.createdAt).format('DD/MM/YYYY')}</div>,
        },
    ];
    return (
        <div>
            <Drawer>
                <Breadcrumbs />
                <div
                    style={{
                        //width: 800,
                        alignItems: "center",

                        flexDirection: "column",
                    }}
                >
                    <div
                        style={{
                            width: 800,
                            alignItems: "center",
                            justifyContent: "center",
                            alignSelf: "center",
                            //flex: 1,
                            //display: "flex",
                        }}
                    >
                        <div className={classes.table} style={{ maxWidth: "100%" }}>
                            <MaterialTable
                                theme={Theme}
                                columns={col}
                                data={impruAdmins}
                                title="Support Users"
                                options={{
                                    exportButton: true,
                                    // selection: true,
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        bottom: 0,
                        right: 0,
                        marginBottom: 16,
                        marginRight: 16,
                        position: "fixed",
                    }}
                >
                    <Tooltip title="New Impru Admin" aria-label="add">
                        <Fab
                            color="primary"
                            aria-label="add"
                            onClick={() => {
                                props.history.push("/Create Support User");
                            }}
                        >
                            <AddIcon style={{ color: "white" }} />
                        </Fab>
                    </Tooltip>
                </div>
            </Drawer>
        </div>
    );
};

export default ImpruAdmins;

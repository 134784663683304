import axios from "axios";
import { baseUrl } from "../../config/baseUrl";
import {setAlert} from './Alert'
export const CREATE_MAIN_INST_ADMIN_REQUEST = "CREATE_MAIN_INST_ADMIN_REQUEST";
export const CREATE_MAIN_INST_ADMIN_FAILURE = "CREATE_MAIN_INST_ADMIN_FAILURE";
export const CREATE_MAIN_INST_ADMIN_SUCCESS = "CREATE_MAIN_INST_ADMIN_SUCCESS";
export const EDIT_MAIN_INST_ADMIN_REQUEST = "EDIT_MAIN_INST_ADMIN_REQUEST";
export const EDIT_MAIN_INST_ADMIN_FAILURE = "EDIT_MAIN_INST_ADMIN_FAILURE";
export const EDIT_MAIN_INST_ADMIN_SUCCESS = "EDIT_MAIN_INST_ADMIN_SUCCESS";

export const GET_MAIN_INST_ADMINS_REQUEST = "GET_MAIN_INST_ADMINS_REQUEST";
export const GET_MAIN_INST_ADMINS_FAILURE = "GET_MAIN_INST_ADMINS_FAILURE";
export const GET_MAIN_INST_ADMINS_SUCCESS = "GET_MAIN_INST_ADMINS_SUCCESS";

export const GET_MAIN_INST_ADMIN_DETAIL_REQUEST =
  "GET_MAIN_INST_ADMIN_DETAIL_REQUEST";
export const GET_MAIN_INST_ADMIN_DETAIL_FAILURE =
  "GET_MAIN_INST_ADMIN_DETAIL_FAILURE";
export const GET_MAIN_INST_ADMIN_DETAIL_SUCCESS =
  "GET_MAIN_INST_ADMIN_DETAIL_SUCCESS";

export const createMainInstituteAdmin = (userData) => async (dispatch) => {
  const { name, email, phoneNumber, mainInstituteId, isAdmin ,instituteIds} = userData;
  dispatch({
    type: CREATE_MAIN_INST_ADMIN_REQUEST,
  });
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({
        name,
        email,
        phoneNumber,
        mainInstituteId,
        instituteIds,
        isAdmin,
      });
      console.log(body);

      const res = await axios.post(
        baseUrl + "/api/institute-admin/create",
        body,
        config
      );
      if (res.status === 200) {
        dispatch({
          type: CREATE_MAIN_INST_ADMIN_SUCCESS,
          payload: res.data.message,
        });
        dispatch(setAlert({message:res.data.message,alertType:'success'}))
      } else {
        dispatch({
          type: CREATE_MAIN_INST_ADMIN_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      dispatch(setAlert({message:err.response.data.messages[0].msg,alertType:'error'}))
      dispatch({
        type: CREATE_MAIN_INST_ADMIN_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

export const getMainInstituteAdmin = (userId) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_MAIN_INST_ADMIN_DETAIL_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.get(
        `${baseUrl}/api/institute-admin/${userId}`,
        config
      );
      console.log(res);
      if (res.status === 200) {
        dispatch({
          type: GET_MAIN_INST_ADMIN_DETAIL_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_MAIN_INST_ADMIN_DETAIL_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      console.log("get MAIN_INST_ADMIN by id fail");

      dispatch({
        type: GET_MAIN_INST_ADMIN_DETAIL_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

export const getAllMainInstituteAllAdmins = (mainInstituteId) => async (
  dispatch
) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_MAIN_INST_ADMINS_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.get(
        `${baseUrl}/api/institute-admin/institute/${mainInstituteId}`,
        config
      );
      console.log(res);
      if (res.status === 200) {
        dispatch({
          type: GET_MAIN_INST_ADMINS_SUCCESS,
          payload: res.data.instituteAdmins,
        });
      } else {
        dispatch({
          type: GET_MAIN_INST_ADMINS_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      console.log("get MAIN_INST_ADMIN failure");

      dispatch({
        type: GET_MAIN_INST_ADMINS_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

export const editMainInstitueAdmin = (id, userData) => async (dispatch) => {
  const { name, email, phoneNumber } = userData;
  dispatch({
    type: EDIT_MAIN_INST_ADMIN_REQUEST,
  });
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({
        name,
        email,
        phoneNumber,
      });

      const res = await axios.put(`${baseUrl}/MAIN_INST_ADMIN/`, body, config);

      if (res.status === 2000) {
        dispatch({
          type: EDIT_MAIN_INST_ADMIN_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: EDIT_MAIN_INST_ADMIN_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_MAIN_INST_ADMIN_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

import {
  GET_ALL_INSTITUTES_REQUEST,
  GET_ALL_INSTITUTES_FAILURE,
  GET_ALL_INSTITUTES_SUCCESS,
  GET_ALL_INSTITUTES_BY_MAIN_INST_ID_REQUEST,
  GET_ALL_INSTITUTES_BY_MAIN_INST_ID_SUCCESS,
  GET_ALL_INSTITUTES_BY_MAIN_INST_ID_FAILURE,
  GET_INSTITUTE_DETAIL_REQUEST,
  GET_INSTITUTE_DETAIL_FAILURE,
  GET_INSTITUTE_DETAIL_SUCCESS,
  GET_INSTITUTE_SYLLABUS_REQUEST,
  GET_INSTITUTE_SYLLABUS_FAILURE,
  GET_INSTITUTE_SYLLABUS_SUCCESS,
  SET_INSTITUTE,
} from "../actions/Institutes";

const initialState = {
  institutes: [],
  instituteId: null,
  institute: null,
  syllabus: null,
  isLoading: false,
  error: null,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_INSTITUTE:
      return {
        ...state,
        instituteId: action.payload,
      };
    case GET_ALL_INSTITUTES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ALL_INSTITUTES_SUCCESS:
      return {
        ...state,
        institutes: action.payload.institutes,
        isLoading: false,
      };
    case GET_ALL_INSTITUTES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case GET_ALL_INSTITUTES_BY_MAIN_INST_ID_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ALL_INSTITUTES_BY_MAIN_INST_ID_SUCCESS:
      return {
        ...state,
        institutes: action.payload,
        isLoading: false,
      };
    case GET_ALL_INSTITUTES_BY_MAIN_INST_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case GET_INSTITUTE_DETAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_INSTITUTE_DETAIL_SUCCESS:
      return {
        ...state,
        institute: action.payload,
        isLoading: false,
      };
    case GET_INSTITUTE_DETAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case GET_INSTITUTE_SYLLABUS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_INSTITUTE_SYLLABUS_SUCCESS:
      return {
        ...state,
        syllabus: action.payload,
        isLoading: false,
      };
    case GET_INSTITUTE_SYLLABUS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    default:
      return state;
  }
}

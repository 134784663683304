import React, { useEffect } from "react";
import Drawer from "../components/Drawer";
import { Grid, Fab, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import InstituteItem from "../components/InstituteItem";
import Select from "../components/Select";
import Breadcrumbs from "../components/Breadcrumbs";
import { useSelector, useDispatch } from "react-redux";
import { getAllMainInsitutes } from "../store/actions/MainInstitute";
import { getInstituteGroups } from "../store/actions/Groups";
import { getInstituteTeachers } from "../store/actions/Teachers";
import {
  getInstituteDetail,
  getAllInstitutesByMainInstiuteId,
  setInstitute,
} from "../store/actions/Institutes";

const Institutes = (props) => {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const [maininstitute, setMainInstitute] = React.useState("");
  const mainInstitutes = useSelector(
    (state) => state.mainInstitute.mainInstitutes
  );
  const insts = useSelector((state) => state.institutes.institutes);

  //const isLoading = useSelector((state) => state.groups.isLoading);

  const handleMainInstituteChange = (e) => {
    e.preventDefault();
    setMainInstitute([e.target.value]);
    console.log(mainInstitutes[e.target.value]._id);
    dispatch(
      getAllInstitutesByMainInstiuteId(mainInstitutes[e.target.value]._id)
    );
  };
  const onClickToUpdateContent = ({ iName, instId }) => {
    // dispatch(getInstituteDetail(instId));
    dispatch(setInstitute({ instituteId: instId }));
    props.history.push(`/Institute Content`);
  };

  const onClickGetInstituteDetail = ({ instId, iName }) => {
    dispatch(getInstituteDetail(instId));
    props.history.push(`/Institute Details`);
  };

  const onClickGetInstituteGroups = ({ iName, instId }) => {
    dispatch(getInstituteGroups(instId));
    props.history.push(`Groups`);
  };
  const onClickGetInstituteTeachers = ({ iName, instId }) => {
    dispatch(getInstituteTeachers(instId));
    props.history.push("/Teachers")
  }

  useEffect(() => {
    dispatch(getAllMainInsitutes());
  }, [dispatch]);

  return (
    <div>
      <Drawer>
        <Breadcrumbs />
        <div
          style={{
            // width: 800,
            //alignItems: "center",
            justifyContent: "space-around",
            //alignSelf: "center",
            //alignContent: "center",
            //flex: 1,
            // display: "flex",
            //flexDirection: "column",
          }}
        >
          <div>
            <Select
              options={mainInstitutes}
              minWidth={800}
              firstMenu="Select Main Institute"
              label="Main Institute"
              value={maininstitute}
              onChange={handleMainInstituteChange}
            />
          </div>
          <div
            style={{
              width: 800,
              flexGrow: 1,
              marginLeft: 20,
            }}
          >
            <Grid
              container
              // direction="row"
              //justify="center"
              //alignItems="center"
              spacing={3}
            >
              {insts.length > 0 ? (
                insts.map((g, i) => (
                  <Grid item sm={12} xs={12} md={6} lg={6} key={i.toString()}>
                    <InstituteItem
                      onClickToUpdateContent={() => {
                        onClickToUpdateContent({ iName: g.name, instId: g._id });
                      }}
                      onClickGetInstituteDetail={() => {
                        onClickGetInstituteDetail({ iName: g.name, instId: g._id });
                      }}
                      institute={g}
                      onClickGetInstituteGroups={() => {
                        onClickGetInstituteGroups({ iName: g.name, instId: g._id });
                      }}
                      onClickGetInstituteTeachers={() => {
                        onClickGetInstituteTeachers({ iName: g.name, instId: g._id });
                      }}
                    />
                  </Grid>
                ))
              ) : (
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    width: 500,
                    height: 500,
                    backgroundColor: "whitesmoke",
                    marginTop: 16,
                    borderRadius: 8,
                    // alignItems: "center",
                  }}
                >
                  <h5
                    style={{
                      display: "flex",
                      // flex: 1,
                      justifyContent: "center",
                      //erticalAlign: "center",
                      //alignContent: "center",
                      alignItems: "center",
                      // marginTop: 300,
                    }}
                  >
                    Please select the main institute

                  </h5>
                </div>
              )}
            </Grid>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            bottom: 0,
            right: 0,
            marginBottom: 16,
            marginRight: 16,
            position: "fixed",
          }}
        >
          <Tooltip title="New Institute" aria-label="add">
            <Fab
              color="primary"
              aria-label="add"
              onClick={() => {
                props.history.push("/Create Institute");
              }}
            >
              <AddIcon style={{ color: "white" }} />
            </Fab>
          </Tooltip>
        </div>
      </Drawer>
    </div>
  );
};

export default Institutes;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "360px",
    },
  },
}));

const CustomTextField = (props) => {
  const classes = useStyles();

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <TextField
        id={props.lable}
        label={props.label}
        variant="outlined"
        color="primary"
        type="password"
        value={props.value}
        onChange={props.onChange}
        style={props.width ? {width:props.width }: { width: 360 }}
        name={props.name && props.name}
        size="medium"
        helperText={props.error && props.error}
        error={props.error ? true : false}
      />
    </form>
  );
};
export default CustomTextField;

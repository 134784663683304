import axios from 'axios';
import { baseUrl } from "../config/baseUrl";
const setAuthToken = token => {
  if (token) {
    // Apply to every request
    axios.defaults.headers.common['Authorization'] = token;
    // axios.defaults.baseURL = baseUrl; // to enable this remove based url in all axios req
  } else {
    // Delete auth header
    delete axios.defaults.headers.common['Authorization'];
  }
};

export default setAuthToken;

import axios from "axios";
import { baseUrl } from "../../config/baseUrl";
import jwt_decode from "jwt-decode";
import {setAlert} from './Alert'
export const SET_INSTITUTE = "SET_INSTITUTE";

export const CREATE_NEW_INSTITUTE_REQUEST = "CREATE_NEW_INSTITUTE_REQUEST";
export const CREATE_NEW_INSTITUTE_FAILURE = "CREATE_NEW_INSTITUTE_FAILURE";
export const CREATE_NEW_INSTITUTE_SUCCESS = "CREATE_NEW_INSTITUTE_SUCCESS";

export const GET_ALL_INSTITUTES_REQUEST = "GET_ALL_INSTITUTES_REQUEST";
export const GET_ALL_INSTITUTES_FAILURE = "GET_ALL_INSTITUTES_FAILURE";
export const GET_ALL_INSTITUTES_SUCCESS = "GET_ALL_INSTITUTES_SUCCESS";

export const GET_ALL_INSTITUTES_BY_MAIN_INST_ID_REQUEST =
  "GET_ALL_INSTITUTES_BY_MAIN_INST_ID_REQUEST";
export const GET_ALL_INSTITUTES_BY_MAIN_INST_ID_FAILURE =
  "GET_ALL_INSTITUTES_BY_MAIN_INST_ID_FAILURE";
export const GET_ALL_INSTITUTES_BY_MAIN_INST_ID_SUCCESS =
  "GET_ALL_INSTITUTES_BY_MAIN_INST_ID_SUCCESS";

export const GET_INSTITUTE_DETAIL_REQUEST = "GET_INSTITUTE_DETAIL_REQUEST";
export const GET_INSTITUTE_DETAIL_FAILURE = "GET_INSTITUTE_DETAIL_FAILURE";
export const GET_INSTITUTE_DETAIL_SUCCESS = "GET_INSTITUTE_DETAIL_SUCCESS";

export const GET_INSTITUTE_SYLLABUS_REQUEST = "GET_INSTITUTE_SYLLABUS_REQUEST";
export const GET_INSTITUTE_SYLLABUS_FAILURE = "GET_INSTITUTE_SYLLABUS_FAILURE";
export const GET_INSTITUTE_SYLLABUS_SUCCESS = "GET_INSTITUTE_SYLLABUS_SUCCESS";

export const setInstitute = ({ instituteId }) => async (dispatch) => {
  dispatch({
    type: SET_INSTITUTE,
    payload: instituteId,
  });
};

export const createNewInstitute = (instData) => async (dispatch) => {
  const {
    mainInstituteId,
    name,
    description,
    email,
    phoneNumber,
    address,
    isPayee,
    instituteMode,
    instituteType,
    academicInfo,
    content,
  } = instData;
  dispatch({
    type: CREATE_NEW_INSTITUTE_REQUEST,
  });
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({
        mainInstituteId,
        name,
        description,
        email,
        phoneNumber,
        address,
        isPayee,
        instituteMode,
        instituteType,
        academicInfo,
        content,
      });
      console.log(body);
      const res = await axios.post(
        `${baseUrl}/api/institute/create`,
        body,
        config
      );
      if (res.status === 200) {
     
        dispatch({
          type: CREATE_NEW_INSTITUTE_SUCCESS,
          payload: res.data.message,
        });
        dispatch(setAlert({message:res.data.message,alertType:'success'}))
      } else {
        dispatch({
          type: CREATE_NEW_INSTITUTE_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      dispatch(setAlert({message:err.response.data.messages[0].msg,alertType:'error'}))
      dispatch({
        type: CREATE_NEW_INSTITUTE_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

export const getAllInstitutes = () => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_ALL_INSTITUTES_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        //Authorization: token,
      },
    };

    try {
      const res = await axios.get(
        `${baseUrl}/api/institute/main-institute/all`,
        config
      );
      console.log(res);
      //.then((res) => {
      if (res.status === 200) {
        //  console.log(res);
        dispatch({
          type: GET_ALL_INSTITUTES_SUCCESS,
          payload: res.data,
        });
      } else {
        console.log(res.response.data.message);
        dispatch({
          type: GET_ALL_INSTITUTES_FAILURE,
          payload: res.data,
        });
      }
      // });
    } catch (err) {
      console.log(err.response.data.message);

      dispatch({
        type: GET_ALL_INSTITUTES_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};
export const getAllInstitutesByMainInstiuteId = (mainInstId) => async (
  dispatch
) => {
  console.log("main_id" + mainInstId);
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_ALL_INSTITUTES_BY_MAIN_INST_ID_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        //Authorization: token,
      },
    };

    try {
      const res = await axios.get(
        `${baseUrl}/api/institute/main-institute/${mainInstId}`,
        config
      );
      //.then((res) => {
      if (res.status == 200) {
        console.log(res.data);
        dispatch({
          type: GET_ALL_INSTITUTES_BY_MAIN_INST_ID_SUCCESS,
          payload: res.data.institutes,
        });
      } else {
        console.log(res.response.data.message);
        dispatch({
          type: GET_ALL_INSTITUTES_BY_MAIN_INST_ID_FAILURE,
          payload: res.data,
        });
      }
      // });
    } catch (err) {
      console.log(err.response.data.message);

      dispatch({
        type: GET_ALL_INSTITUTES_BY_MAIN_INST_ID_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

export const getInstituteDetail = (instituteId) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_INSTITUTE_DETAIL_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    try {
      const res = await axios.get(
        `${baseUrl}/api/institute/${instituteId}`,
        config
      );
      // console.log("inst detail" + res);
      if (res.status === 200) {
        // console.log("inst detail" + res);
        dispatch({
          type: GET_INSTITUTE_DETAIL_SUCCESS,
          payload: res.data.institute,
        });
      } else {
        dispatch({
          type: GET_INSTITUTE_DETAIL_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      console.log("get institutes failure");

      dispatch({
        type: GET_INSTITUTE_DETAIL_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

export const getInstituteSyllabus = (instituteId) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_INSTITUTE_SYLLABUS_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    try {
      const res = await axios.get(
        `${baseUrl}/api/institute-content/content-provider/${instituteId}`,
        config
      );
      // console.log("inst detail" + res);
      if (res.status === 200) {
        dispatch({
          type: GET_INSTITUTE_SYLLABUS_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_INSTITUTE_SYLLABUS_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      console.log("get institutes failure");

      dispatch({
        type: GET_INSTITUTE_SYLLABUS_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

import React from "react";
import Drawer from "../components/Drawer";
import CustomTextField from "../components/CustomTextField";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import { Alert } from "@material-ui/lab";
import { makeStyles, Button } from "@material-ui/core";
import Breadcrumbs from "../components/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { createImpruAdmin } from "../store/actions/ImpruAdmins";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const CreateImpruAdmin = (props) => {
  const classes = useStyles();
  const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  const dispatch = useDispatch();
  const message = useSelector((state) => state.impruAdmins.message);
  const error = useSelector((state) => state.impruAdmins.error);
  const alert = useSelector((state) => state.alert.alert);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phoneNumber, setPhone] = React.useState("");

  const [nameError, setNameError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");
  const [commonError, setCommonError] = React.useState("");

  const handleNameChange = (e) => {
    e.preventDefault();

    setName(e.target.value);
    setNameError("");
    setCommonError("")
  };
  const handleEmailChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
    setEmailError("");
    setCommonError("")
  };

  const handlePhoneNumberChange = (e) => {
    e.preventDefault();
    setPhone(e.target.value);
    setPhoneError("");
    setCommonError("")
  };

  const validate = () => {
    if (name === "") {
      setNameError("Name is required");
      setCommonError("Name is required");

      return false;
    }
    if (name.length < 8) {
      setNameError("Name is too small");
      setCommonError("Name is too small");
      return false;
    }
    if (email === "") {
      setEmailError("Email is required");
      setCommonError("Email is required");
      return false;
    }
    if (email.length > 0) {
      if (!regexp.test(email)) {
        setEmailError("Invalid Email");
        setCommonError("Invalid Email");
        return false;
      }
    }
    if (phoneNumber === "") {
      setPhoneError("Phone is required");
      setCommonError("Phone is required");
      return false;
    }
    if (phoneNumber.length > 0) {
      if (!phoneno.test(phoneNumber)) {
        setPhoneError("Invalid Phone Number");
        setCommonError("Invalid Phone Number");
        return false;
      }
    }

    return true;
  };
  const goBack = () => {
    props.history.goBack();
  };

  const onClickCreateImpruAdmin = () => {
    const isValid = validate();
    if (isValid) {
      dispatch(
        createImpruAdmin({
          name,
          email,
          phoneNumber,
          isAdmin: true,
        })
      );
      //   setName("");
      //   setEmail("");
      //   setPhone("");
    }
  };
  return (
    <div>
      <Drawer>
        <Breadcrumbs />
        <div
          style={{
            alignItems: "flex-start",
            justifyContent: "flex-start",
            // alignSelf: "center",
            display: "flex",
            flexDirection: "column",
            // marginLeft: 40,
          }}
        >


          <CustomTextField
            label="Name"
            value={name}
            onChange={handleNameChange}
            error={nameError}
          />
          <CustomTextField
            label="Email"
            value={email}
            onChange={handleEmailChange}
            error={emailError}
          />
          <CustomTextField
            label="Phone Number"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            error={phoneError}
          />

          <div style={{ display: 'flex', justifyContent: "space-evenly" }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              onClick={onClickCreateImpruAdmin}
              style={{ margin: 8, minWidth: 170, height: 50 }}

            >
              Create
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              className={classes.button}
              style={{ margin: 8, minWidth: 170, height: 50 }}

              onClick={goBack}
            >
              Cancel
            </Button>


          </div>
          <div style={{ width: 360,marginLeft:8 }}>
            {alert && (
              <Alert icon={false} severity={alert.alertType}>{alert.message}</Alert>
            )}
            {commonError && (
              <Alert icon={false} severity="error" >{commonError}</Alert>
            )}
            {!commonError && (
              <div style={{ height: 48, width: 360, }} ></div>
            )}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default CreateImpruAdmin;

import React, { useEffect, useState } from "react";
import Drawer from "../components/Drawer";
import { List, ListItem, ListItemText, Divider } from "@material-ui/core";
import Breadcrumbs from "../components/Breadcrumbs";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllContentProviders,
  getBoardsByContentProvider,
  getStandardsByBoardId,
  getCoursesByStandardId,
  getSubjectsByCourseId,
} from "../store/actions/Content";
import Select from "../components/Select";
import ContentProviderItem from "../components/ContentProviderItem";
import BoardItem from "../components/BoardItem";
import StandardItem from "../components/StandardItem";
import CourseItem from "../components/CourseItem";
import SubjectItem from "../components/InstituteSubjectItem";

const InstituteContent = (props) => {
  const dispatch = useDispatch();

  const contentProviders = useSelector(
    (state) => state.content.contentProviders
  );
  const boards = useSelector((state) => state.content.boards);
  const standards = useSelector((state) => state.content.standards);
  const courses = useSelector((state) => state.content.courses);
  const subjects = useSelector((state) => state.content.subjects);
  const isLoading = useSelector((state) => state.content.isLoading);

  const [contentProvider, setContentProvider] = useState("");
  const [board, setBoard] = useState("");
  const [standard, setStandard] = useState("");
  const [course, setCourse] = useState("");

  const [contentProviderError, setContentProviderError] = useState("");
  const [boardError, setBoardError] = useState("");
  const [standardError, setStandardError] = useState("");
  const [courseError, setCourseError] = useState("");

  useEffect(() => {
    dispatch(getAllContentProviders());
  }, []);

  const handleContentProviderChange = (e) => {
    e.preventDefault();
    // console.log("value" + e.target.value);
    if (e.target.value !== "") {
      setContentProvider([e.target.value]);
      dispatch(
        getBoardsByContentProvider({
          contentProviderId: contentProviders[e.target.value]._id,
        })
      );
    }
  };

  const handleBoardChange = (e) => {
    e.preventDefault();

    if (e.target.value !== "") {
      setBoard([e.target.value]);
      dispatch(
        getStandardsByBoardId({
          boardId: boards[e.target.value]._id,
        })
      );
      setBoardError("");
      setStandard("");
      setCourse("");
    }
  };
  // console.log(standard);
  const handleStandardChange = (e) => {
    e.preventDefault();

    if (e.target.value !== "") {
      setStandard([e.target.value]);
      dispatch(
        getCoursesByStandardId({
          standardId: standards[e.target.value]._id,
        })
      );
      setStandardError("");
      setCourse("");
    }
  };
  const handleCourseChange = (e) => {
    e.preventDefault();
    if (e.target.value !== "") {
      setCourse([e.target.value]);
      dispatch(
        getSubjectsByCourseId({
          courseId: courses[e.target.value]._id,
        })
      );
      setCourseError("");
    }
  };
  return (
    <div>
      <Drawer>
        <Breadcrumbs />
        <div
          style={{
            //alignContent: "center",
            //alignItems: "center",
            justifyContent: "center",
            // alignSelf: "center",
            // display: "flex",
          }}
        >
          <List>
            <ListItem selected={true}>
              <ListItemText primary="Content Providers" />
            </ListItem>
          </List>
          <div>
            {contentProviders.length > 0 &&
              contentProviders.map((cp, index) => (
                <ContentProviderItem
                  index={index}
                  contentProviderId={cp._id}
                  name={cp.name}
                  imageUrl={cp.imageUrl}
                />
              ))}
          </div>
          <Divider />
          <List>
            <ListItem selected={true}>
              <ListItemText primary="Boards" />
            </ListItem>
          </List>
          <Select
            options={contentProviders}
            firstMenu="Select Content Provider"
            label="Content Providers"
            value={contentProvider}
            onChange={handleContentProviderChange}
            error={contentProviderError}
          />
          <div>
            {boards.length > 0 &&
              boards.map((b) => (
                <BoardItem
                  contentProviderId={b.contentProvider}
                  boardId={b._id}
                  name={b.name}
                  imageUrl={b.imageUrl}
                />
              ))}
          </div>
          <Divider />
          <List>
            <ListItem selected={true}>
              <ListItemText primary="Standards" />
            </ListItem>
          </List>
          <Select
            options={boards}
            firstMenu="Select Board"
            label="Boards"
            value={board}
            onChange={handleBoardChange}
            error={boardError}
          />
          <div style={{ flexDirection: "row", flex: 1, display: "flex" }}>
            {standards.length > 0 &&
              standards.map((i) => (
                <StandardItem
                  standardId={i._id}
                  boardId={i.board}
                  name={i.name}
                  imageUrl={i.imageUrl}
                />
              ))}
          </div>
          <Divider />
          <List>
            <ListItem selected={true}>
              <ListItemText primary="Courses" />
            </ListItem>
          </List>
          <Select
            options={standards}
            firstMenu="Select Standard"
            label="Standards"
            value={standard}
            onChange={handleStandardChange}
            error={standardError}
          />
          <div>
            {courses.length > 0 &&
              courses.map((i) => (
                <CourseItem
                  standardId={i.standard}
                  courseId={i._id}
                  name={i.name}
                  imageUrl={i.imageUrl}
                />
              ))}
          </div>
          <Divider />
          <List>
            <ListItem selected={true}>
              <ListItemText primary="Subjects" />
            </ListItem>
          </List>
          <Select
            options={courses}
            firstMenu="Select Course"
            label="Courses"
            value={course}
            onChange={handleCourseChange}
            error={courseError}
          />
          <div style={{ flexDirection: "row", flex: 1, display: "flex" }}>
            {subjects.length > 0 &&
              subjects.map((i) => (
                <SubjectItem
                  subjectId={i._id}
                  courseId={i.course}
                  name={i.name}
                  imageUrl={i.imageUrl}
                />
              ))}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default InstituteContent;

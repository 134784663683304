import {
  CREATE_MAIN_INST_ADMIN_REQUEST,
  CREATE_MAIN_INST_ADMIN_FAILURE,
  CREATE_MAIN_INST_ADMIN_SUCCESS,
  GET_MAIN_INST_ADMINS_FAILURE,
  GET_MAIN_INST_ADMINS_SUCCESS,
  GET_MAIN_INST_ADMINS_REQUEST,
  GET_MAIN_INST_ADMIN_DETAIL_REQUEST,
  GET_MAIN_INST_ADMIN_DETAIL_FAILURE,
  GET_MAIN_INST_ADMIN_DETAIL_SUCCESS,
  EDIT_MAIN_INST_ADMIN_REQUEST,
  EDIT_MAIN_INST_ADMIN_FAILURE,
  EDIT_MAIN_INST_ADMIN_SUCCESS,
} from "../actions/MainInstituteAdmins";

const initialState = {
  mainInstituteAdmins: [],
  mainInstituteAdmin: null,
  error: null,
  message: null,
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_MAIN_INST_ADMIN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case CREATE_MAIN_INST_ADMIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        message: action.payload,
      };

    case CREATE_MAIN_INST_ADMIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GET_MAIN_INST_ADMINS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_MAIN_INST_ADMINS_SUCCESS:
      return {
        ...state,
        mainInstituteAdmins: action.payload,
        isLoading: false,
        error: null,
      };
    case GET_MAIN_INST_ADMINS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GET_MAIN_INST_ADMIN_DETAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_MAIN_INST_ADMIN_DETAIL_SUCCESS:
      return {
        ...state,
        mainInstituteAdmin: action.payload,
        isLoading: false,
        error: null,
      };
    case GET_MAIN_INST_ADMIN_DETAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case EDIT_MAIN_INST_ADMIN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_MAIN_INST_ADMIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      };

    case EDIT_MAIN_INST_ADMIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}

import axios from "axios";
import { baseUrl } from "../../config/baseUrl";
import {setAlert} from './Alert'
export const CREATE_TEACHER_REQUEST = "CREATE_TEACHER_REQUEST";
export const CREATE_TEACHER_FAILURE = "CREATE_TEACHER_FAILURE";
export const CREATE_TEACHER_SUCCESS = "CREATE_TEACHER_SUCCESS";

export const CREATE_MULTIPLE_TEACHERS_REQUEST =
  "CREATE_MULTIPLE_TEACHERS_REQUEST";
export const CREATE_MULTIPLE_TEACHERS_FAILURE =
  "CREATE_MULTIPLE_TEACHERS_FAILURE";
export const CREATE_MULTIPLE_TEACHERS_SUCCESS =
  "CREATE_MULTIPLE_TEACHERS_SUCCESS";

export const EDIT_TEACHER_REQUEST = "EDIT_TEACHER_REQUEST";
export const EDIT_TEACHER_FAILURE = "EDIT_TEACHER_FAILURE";
export const EDIT_TEACHER_SUCCESS = "EDIT_TEACHER_SUCCESS";

export const GET_INSTITUTE_TEACHERS_REQUEST = "GET_INSTITUTE_TEACHERS_REQUEST";
export const GET_INSTITUTE_TEACHERS_FAILURE = "GET_INSTITUTE_TEACHERS_FAILURE";
export const GET_INSTITUTE_TEACHERS_SUCCESS = "GET_INSTITUTE_TEACHERS_SUCCESS";
export const GET_TEACHER_DETAIL_REQUEST = "GET_TEACHER_DETAIL_REQUEST";
export const GET_TEACHER_DETAIL_FAILURE = "GET_TEACHER_DETAIL_FAILURE";
export const GET_TEACHER_DETAIL_SUCCESS = "GET_TEACHER_DETAIL_SUCCESS";

export const ADD_SUBJECT_TEACHER_REQUEST = "ADD_SUBJECT_TEACHER_REQUEST";
export const ADD_SUBJECT_TEACHER_FAILURE = "ADD_SUBJECT_TEACHER_FAILURE";
export const ADD_SUBJECT_TEACHER_SUCCESS = "ADD_SUBJECT_TEACHER_SUCCESS";

export const createTeacher = (userData) => async (dispatch) => {
  const { name, email, phone, instituteId, password } = userData;
  dispatch({
    type: CREATE_TEACHER_REQUEST,
  });
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({
        name,
        email,
        phoneNumber: phone,
        instituteId,
        password,
      });
      console.log(body);

      const res = await axios.post(baseUrl + "/api/teacher/add", body, config);
      if (res.status === 200) {
        dispatch({
          type: CREATE_TEACHER_SUCCESS,
          payload: res.data.message,
        });
        dispatch(setAlert({message:res.data.message,alertType:'success'}))
      } else {
        dispatch({
          type: CREATE_TEACHER_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      dispatch(setAlert({message:err.response.data.messages[0].msg,alertType:'error'}))
      dispatch({
        type: CREATE_TEACHER_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

export const createMultipleTeachers = (userData) => async (dispatch) => {
  const { teachers, instituteId, password } = userData;
  dispatch({
    type: CREATE_MULTIPLE_TEACHERS_REQUEST,
  });
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({
        teachers,
        instituteId,
        password,
      });
      console.log(body);

      const res = await axios.post(
        baseUrl + "/api/teacher/add-multiple",
        body,
        config
      );
      if (res.status === 200) {
        dispatch({
          type: CREATE_MULTIPLE_TEACHERS_SUCCESS,
          payload: res.data.message,
        });
        dispatch(setAlert({message:res.data.message,alertType:'success'}))
      } else {
        dispatch({
          type: CREATE_MULTIPLE_TEACHERS_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      dispatch({
        type: CREATE_MULTIPLE_TEACHERS_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

export const addTeacher = (userData) => async (dispatch) => {
  const { groupId, teacherId, subjectId } = userData;
  dispatch({
    type: ADD_SUBJECT_TEACHER_REQUEST,
  });
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({
        groupId,
        teacherId,
        subjectId,
      });
      //      console.log(body);

      const res = await axios.put(
        baseUrl + "/api/group/add/teacher-subject",
        body,
        config
      );
      console.log(res);
      if (res.status === 200) {
        dispatch({
          type: ADD_SUBJECT_TEACHER_SUCCESS,
          payload: "Added Teacher Successfully",
        });
      } else {
        dispatch({
          type: ADD_SUBJECT_TEACHER_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      dispatch({
        type: CREATE_TEACHER_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

export const getTeacherById = (userId) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_TEACHER_DETAIL_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.get(`${baseUrl}/teacher/${userId}`, config);
      console.log(res);
      if (res.status === 200) {
        dispatch({
          type: GET_TEACHER_DETAIL_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_TEACHER_DETAIL_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      console.log("get teacher by id fail");

      dispatch({
        type: GET_TEACHER_DETAIL_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

export const getInstituteTeachers = (instituteId) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_INSTITUTE_TEACHERS_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.get(
        `${baseUrl}/api/teacher/${instituteId}`,
        config
      );
      console.log(res.data);
      if (res.status === 200) {
        //console.log(res.data.teachers);
        let tchrs = [];
        res.data.teachers.forEach((tchr) => {
          let t = {
            ...tchr,
            id: tchr._id,
          };
          tchrs.push(t);
        });

        console.log("thcers " + tchrs);
        dispatch({
          type: GET_INSTITUTE_TEACHERS_SUCCESS,
          payload: tchrs,
        });
      } else {
        dispatch({
          type: GET_INSTITUTE_TEACHERS_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      console.log("get teacher failure");

      dispatch({
        type: GET_INSTITUTE_TEACHERS_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

export const editTeacher = (id, userData) => async (dispatch) => {
  const { name, email, phoneNumber } = userData;
  dispatch({
    type: EDIT_TEACHER_REQUEST,
  });
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({
        name,
        email,
        phoneNumber,
      });

      const res = await axios.put(`${baseUrl}/teacher/`, body, config);

      if (res.status === 2000) {
        dispatch({
          type: EDIT_TEACHER_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: EDIT_TEACHER_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_TEACHER_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

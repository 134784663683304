import React, { useEffect } from "react";
import Drawer from "../components/Drawer";
//import Typography from "@material-ui/core/Typography";
import Select from "../components/Select";

import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import Breadcrumbs from "../components/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { getGroupSubjects, getInstituteGroups } from "../store/actions/Groups";
import {
  getAllInstitutes,
  getAllInstitutesByMainInstiuteId,
} from "../store/actions/Institutes";
import { getInstituteTeachers, addTeacher } from "../store/actions/Teachers";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles, Button } from "@material-ui/core";
import { getAllMainInsitutes } from "../store/actions/MainInstitute";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const AddTeacher = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [mainInstitute, setMainInstitute] = React.useState("");
  const [institute, setInstitute] = React.useState("");
  const [group, setGroup] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [teacher, setTeacher] = React.useState("");
  const insts = useSelector((state) => state.institutes.institutes);
  const grps = useSelector((state) => state.groups.groups);
  const subs = useSelector((state) => state.groups.subjects);
  const tchrs = useSelector((state) => state.teachers.teachers);
  const message = useSelector((state) => state.teachers.message);
  const maininsts = useSelector((state) => state.mainInstitute.mainInstitutes);
  const error = useSelector((state) => state.teachers.error);

  useEffect(() => {
    dispatch(getAllMainInsitutes());
  }, [dispatch]);

  //console.log(grps);

  const handleMainInstituteChange = (e) => {
    e.preventDefault();
    setMainInstitute([e.target.value]);
    dispatch(getAllInstitutesByMainInstiuteId(maininsts[e.target.value]._id));
  };
  const handleInstituteChange = (e) => {
    e.preventDefault();
    setInstitute([e.target.value]);
    dispatch(getInstituteGroups(insts[e.target.value]._id));
    dispatch(getInstituteTeachers(insts[e.target.value]._id));
  };

  const handleGroupChange = (e) => {
    e.preventDefault();
    setGroup([e.target.value]);
    //console.log(grps[e.target.value]);
    dispatch(getGroupSubjects(grps[e.target.value]._id));
  };
  const handleSubjectChange = (e) => {
    e.preventDefault();
    setSubject([e.target.value]);
  };
  const handleTeacherChange = (e) => {
    e.preventDefault();
    setTeacher([e.target.value]);
  };

  const goBack = () => {
    props.history.goBack();
  };
  const onClickAddTeacher = () => {
    let teacher_subject = {
      teacherId: tchrs[teacher]._id,
      groupId: grps[group]._id,
      subjectId: subs[subject]._id,
    };

    dispatch(addTeacher(teacher_subject));
  };

  return (
    <div>
      <Drawer>
        <Breadcrumbs />
        <div
          style={{
            alignItems: "flex-start",
            justifyContent: "flex-start",
            // alignSelf: "center",
            display: "flex",
            flexDirection: "column",
            //marginLeft: 40,
          }}
        >
          <div style={{ width: 400 }}>
            {error && (
              <Alert severity="error">
                <AlertTitle>Failed</AlertTitle>
                {error}
              </Alert>
            )}
            {message && (
              <Alert severity="error">
                <AlertTitle>{message} </AlertTitle>
                {message}
              </Alert>
            )}
          </div>
          <Select
            options={maininsts}
            firstMenu="Select Main Institute"
            label="Main Institute"
            value={mainInstitute}
            onChange={handleMainInstituteChange}
          />
          <Select
            options={insts}
            firstMenu="Select Institute"
            label="Institute"
            value={institute}
            onChange={handleInstituteChange}
          />

          <Select
            options={grps}
            firstMenu="Select Group"
            label="Group"
            value={group}
            onChange={handleGroupChange}
          />
          <Select
            options={subs}
            firstMenu="Select Subject"
            label="Subjects"
            value={subject}
            onChange={handleSubjectChange}
          />
          <Select
            options={tchrs}
            firstMenu="Select Teacher"
            label="Teachers"
            value={teacher}
            onChange={handleTeacherChange}
          />

          <div style={{ marginTop: 16 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              startIcon={<CancelIcon />}
              onClick={goBack}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              startIcon={<SaveIcon />}
              onClick={onClickAddTeacher}
            >
              Add Teacher
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default AddTeacher;

import { createMuiTheme } from "@material-ui/core/styles";
import amber from "@material-ui/core/colors/teal";
import red from "@material-ui/core/colors/deepOrange";
import green from "@material-ui/core/colors/green";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: amber[700],
    },
    secondary: {
      main: red[700],
    },
    success: {
      main: green[700],
    },
  },
});

export default theme;

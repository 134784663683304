import {
    CREATE_SUPPORT_USER_REQUEST,
    CREATE_SUPPORT_USER_FAILURE,
    CREATE_SUPPORT_USER_SUCCESS,
    GET_ALL_SUPPORT_USERS_FAILURE,
    GET_ALL_SUPPORT_USERS_SUCCESS,
    GET_ALL_SUPPORT_USERS_REQUEST,
    GET_SUPPORT_USER_DETAIL_REQUEST,
    GET_SUPPORT_USER_DETAIL_FAILURE,
    GET_SUPPORT_USER_DETAIL_SUCCESS,
    EDIT_SUPPORT_USER_REQUEST,
    EDIT_SUPPORT_USER_FAILURE,
    EDIT_SUPPORT_USER_SUCCESS,
} from "../actions/SupportUsers";

const initialState = {
    supportUsers: [],
    supportUser: null,
    error: null,
    message: null,
    isLoading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CREATE_SUPPORT_USER_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case CREATE_SUPPORT_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: null,
                message: action.payload,
            };

        case CREATE_SUPPORT_USER_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case GET_ALL_SUPPORT_USERS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case GET_ALL_SUPPORT_USERS_SUCCESS:
            return {
                ...state,
                supportUsers: action.payload,
                isLoading: false,
                error: null,
            };
        case GET_ALL_SUPPORT_USERS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case GET_SUPPORT_USER_DETAIL_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case GET_SUPPORT_USER_DETAIL_SUCCESS:
            return {
                ...state,
                supportUser: action.payload,
                isLoading: false,
                error: null,
            };
        case GET_SUPPORT_USER_DETAIL_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case EDIT_SUPPORT_USER_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case EDIT_SUPPORT_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: null,
            };

        case EDIT_SUPPORT_USER_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        default:
            return state;
    }
}

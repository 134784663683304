import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import {
  Tabs,
  List,
  Chip,
  Divider,
  CardMedia,
  Switch,
  FormControlLabel,
  ListItem,ListItemText
} from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TeacherListItem from "./TeacherListItem";
import StudentListItem from "./StudentListItem";
import GroupSubjectListItem from "./GroupSubjectListItem";
import GroupStandardListItem from "./GroupStandardListItem";
import image from "../assets/images/teamwork.png";
import { FormatColorResetSharp } from "@material-ui/icons";
import { updateGroupStatus, getGroupDetail } from "../store/actions/Groups";
import { useSelector, useDispatch } from "react-redux";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component="span">
          <div style={{ marginLeft: 16, marginRight: 16 }}>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  chip: {
    margin: theme.spacing(0.5),
  },

  section2: {
    margin: theme.spacing(2),
  },
}));

const SimpleTabs = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const instituteId = useSelector((state) => state.institutes.instituteId);
  const groupId = useSelector((state) => state.groups.groupId);
  const group = useSelector((state) => state.groups.group);
  const [groupStatus, setGroupStatus] = React.useState(group.group.isActive);
  const [value, setValue] = React.useState(0);
  console.log(groupStatus);

  useEffect(() => {
    setGroupStatus(group.group.isActive);
  }, [group.group.isActive]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleGroupStatusChange = () => {
    dispatch(updateGroupStatus({ groupId, status: !group.group.isActive }));
    setGroupStatus(!group.group.isActive);
    setTimeout(() => {
      dispatch(getGroupDetail(groupId));
    }, 300);
  };
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Details" {...a11yProps(0)} />
          <Tab label="Teachers" {...a11yProps(1)} />
          <Tab label="Students" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
      {props.group !== null && (
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CardMedia

                image={
                  props.group.group.imageUrl
                    ? props.group.group.imageUrl
                    : image
                }
                style={{
                  width: 100,
                  height: 100,
                  borderRadius: 50,
                  borderWidth: 3,
                  borderColor: "grey",
                  marginTop: 8,
                  padding: 4,
                }}
              />

            </div>
            <div style={{
              textAlign: "center",
              padding: 8
            }}>
              <Typography variant="h6">
                {props.group.group.name}
              </Typography>
              <Typography style={{
                textAlign: "center",
              }}>Class: {props.group.group.standard.name}</Typography>
            </div>

            <div className={classes.subjects}>

              <List>
                <ListItem selected={true}>
                  <ListItemText primary="Description" />
                </ListItem>
              </List>
              <Typography
                color="textSecondary"
                variant="body2"
                style={{ marginBottom: 16 }}
              >
                {props.group.group.description}
              </Typography>
            </div>
            {/* <div className={classes.subjects}>
              <List>
                <ListItem selected={true}>
                  <ListItemText primary="Class" />
                </ListItem>
              </List>
              <GroupStandardListItem standard={props.group.group.standard} />
            </div> */}
            <div className={classes.subjects}>
              <List>
                <ListItem selected={true}>
                  <ListItemText primary="Subjects" />
                </ListItem>
              </List>
              <div>

                <List>
                  {props.group.subjectTeachers.map((sub) => (
                    <GroupSubjectListItem
                      key={sub.subject._id}
                      subject={sub.subject}
                    />
                  ))}
                </List>
              </div>
            </div>
          </div>
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {props.group.subjectTeachers.map((item, i) => (
          <List key={i.toString()}>
            <TeacherListItem teacher={item} />
            <Divider variant="inset" component="li" />
          </List>
        ))}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {props.group.students.map((student, i) => (
          <List dense key={i.toString()}>
            <StudentListItem student={student} />
            <Divider variant="inset" component="li" />
          </List>
        ))}
      </TabPanel>
    </div>
  );
};
export default SimpleTabs;

import React from "react";
import {
  Breadcrumbs as MUIBreadcrumbs,
  Link,
  Typography,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";

const Breadcrumbs = (props) => {
  const {
    history,
    location: { pathname },
  } = props;
  const pathnames = pathname.split("/").filter((x) => x);
  return (
    <div
      style={{
        backgroundColor: "#00796B",
        paddingTop: 16,
        paddingBottom: 16,
        // minHeight: 50,
        marginBottom: 8,
        paddingLeft: 30,
        flexDirection:'row',
        display:'flex',
        // maxWidth:'800px'
      }}
    >
    
        <div style={{display:'flex',flexGrow:1}}>{pathnames.map((name, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
          const isLast = index === pathnames.length - 1;
          return isLast ? (
            <Typography key={name} style={{ color: 'white' }}>{name} </Typography>
          ) : (
            <Link key={name} onClick={() => history.push(routeTo)} color='white'>
              {name}
            </Link>
          );
        })}</div>
        {props.institute&&<Typography  style={{ color: 'white',paddingRight:16 }}>Institute: {props.institute} </Typography>}
        {props.group&&<Typography  style={{ color: 'white',paddingRight:16 }}>Group: {props.group} </Typography>}
    </div>
  );
};

export default withRouter(Breadcrumbs);

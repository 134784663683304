import React, { useEffect } from "react";
import Drawer from "../components/Drawer";

import { Grid, Fab, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import GroupItem from "../components/GroupItem";
import Select from "../components/Select";
import Breadcrumbs from "../components/Breadcrumbs";
import { useSelector, useDispatch } from "react-redux";
import { getAllInstitutesByMainInstiuteId } from "../store/actions/Institutes";
import { getAllMainInsitutes } from "../store/actions/MainInstitute";
import {
  getInstituteGroups,
  getGroupDetail,
  setGroup,
} from "../store/actions/Groups";

const Groups = (props) => {
  const dispatch = useDispatch();
  const [mainInstitute, setMainInstitute] = React.useState("");
  const [institute, setInstitute] = React.useState("");
  const mainInsts = useSelector((state) => state.mainInstitute.mainInstitutes);
  const insts = useSelector((state) => state.institutes.institutes);
  const grps = useSelector((state) => state.groups.groups);

  const handleMainInstituteChange = (e) => {
    e.preventDefault();
    setMainInstitute([e.target.value]);
    dispatch(getAllInstitutesByMainInstiuteId(mainInsts[e.target.value]._id));
  };
  const handleInstituteChange = (e) => {
    e.preventDefault();
    setInstitute([e.target.value]);
    dispatch(getInstituteGroups(insts[e.target.value]._id));
  };
  const onClickGetGroupDetail = ({ groupId, groupName }) => {
    dispatch(getGroupDetail(groupId));
    dispatch(setGroup(groupId));
    props.history.push(`/Group Details`);
  };

  useEffect(() => {
    dispatch(getAllMainInsitutes());
  }, [dispatch]);

  return (
    <div>
      <Drawer>
        <Breadcrumbs />
        <div
          style={{
            // width: 800,
            //alignItems: "center",
            justifyContent: "space-around",
            //alignSelf: "center",
            //alignContent: "center",
            //flex: 1,
            // display: "flex",
            //flexDirection: "column",
          }}
        >
          {/* <div style={{ flexDirection: "row", display: "flex" }}>
            <Select
              options={mainInsts}
              minWidth={400}
              firstMenu="Select Main Institute"
              label=" Main Institute"
              value={mainInstitute}
              onChange={handleMainInstituteChange}
            />
            <Select
              options={insts}
              minWidth={400}
              firstMenu="Select Institute"
              label="Institute"
              value={institute}
              onChange={handleInstituteChange}
            />
          </div> */}

          <div
            style={{
              // width: 800,
              flexGrow: 1,
              // marginLeft: 20,
            }}
          >
            <Grid
              container
              // direction="row"
              //justify="center"
              //alignItems="center"
              spacing={3}
            >
              {grps.length > 0 ? (
                grps.map((g, i) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={i.toString()}>
                    <GroupItem
                      onClick={() => {
                        onClickGetGroupDetail({ groupId: g._id, groupName: g.name });
                      }}
                      group={g}
                    />
                  </Grid>
                ))
              ) : (
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    width: 500,
                    height: 500,
                    backgroundColor: "whitesmoke",
                    marginTop: 16,
                    borderRadius: 8,
                    // alignItems: "center",
                  }}
                >
                  <h5
                    style={{
                      display: "flex",
                      // flex: 1,
                      justifyContent: "center",
                      //erticalAlign: "center",
                      //alignContent: "center",
                      alignItems: "center",
                      // marginTop: 300,
                    }}
                  >
                    Please select the institute
                  </h5>
                </div>
              )}
            </Grid>
          </div>
        </div>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            bottom: 0,
            right: 0,
            marginBottom: 16,
            marginRight: 16,
            position: "fixed",
          }}
        >
          <Tooltip title="New Group" aria-label="add">
            <Fab
              color="primary"
              aria-label="add"
              onClick={() => {
                props.history.push("/create-group");
              }}
            >
              <AddIcon style={{ color: "white" }} />
            </Fab>
          </Tooltip>
        </div> */}
      </Drawer>
    </div>
  );
};

export default Groups;

import React, { useEffect } from "react";
import Routes from "./Routes";
import Theme from "./Theme";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import store from "./redux-store/store";
import { loadUser } from "./store/actions/Auth";
import setAuthToken from "./utils/setAuthToken";
if (localStorage.token) {
  setAuthToken(localStorage.token);
}
const App = () => {
  useEffect(() => {
    if (localStorage.token) {
      store.dispatch(loadUser());
    }
  }, []);
  return (
    <div>
      <ThemeProvider theme={Theme}>
        <Routes />
      </ThemeProvider>
    </div>
  );
};

export default App;

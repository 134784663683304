import {
  CREATE_IMPRU_ADMIN_REQUEST,
  CREATE_IMPRU_ADMIN_FAILURE,
  CREATE_IMPRU_ADMIN_SUCCESS,
  GET_ALL_IMPRU_ADMINS_FAILURE,
  GET_ALL_IMPRU_ADMINS_SUCCESS,
  GET_ALL_IMPRU_ADMINS_REQUEST,
  GET_IMPRU_ADMIN_DETAIL_REQUEST,
  GET_IMPRU_ADMIN_DETAIL_FAILURE,
  GET_IMPRU_ADMIN_DETAIL_SUCCESS,
  EDIT_IMPRU_ADMIN_REQUEST,
  EDIT_IMPRU_ADMIN_FAILURE,
  EDIT_IMPRU_ADMIN_SUCCESS,
} from "../actions/ImpruAdmins";

const initialState = {
  impruAdmins: [],
  impruAdmin: null,
  error: null,
  message: null,
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_IMPRU_ADMIN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case CREATE_IMPRU_ADMIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        message: action.payload,
      };

    case CREATE_IMPRU_ADMIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GET_ALL_IMPRU_ADMINS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ALL_IMPRU_ADMINS_SUCCESS:
      return {
        ...state,
        impruAdmins: action.payload,
        isLoading: false,
        error: null,
      };
    case GET_ALL_IMPRU_ADMINS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GET_IMPRU_ADMIN_DETAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_IMPRU_ADMIN_DETAIL_SUCCESS:
      return {
        ...state,
        impruAdmin: action.payload,
        isLoading: false,
        error: null,
      };
    case GET_IMPRU_ADMIN_DETAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case EDIT_IMPRU_ADMIN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_IMPRU_ADMIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      };

    case EDIT_IMPRU_ADMIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}

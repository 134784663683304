import axios from "axios";
import { baseUrl } from "../../config/baseUrl";
import {setAlert} from './Alert'
export const CREATE_IMPRU_ADMIN_REQUEST = "CREATE_IMPRU_ADMIN_REQUEST";
export const CREATE_IMPRU_ADMIN_FAILURE = "CREATE_IMPRU_ADMIN_FAILURE";
export const CREATE_IMPRU_ADMIN_SUCCESS = "CREATE_IMPRU_ADMIN_SUCCESS";
export const EDIT_IMPRU_ADMIN_REQUEST = "EDIT_IMPRU_ADMIN_REQUEST";
export const EDIT_IMPRU_ADMIN_FAILURE = "EDIT_IMPRU_ADMIN_FAILURE";
export const EDIT_IMPRU_ADMIN_SUCCESS = "EDIT_IMPRU_ADMIN_SUCCESS";

export const GET_ALL_IMPRU_ADMINS_REQUEST = "GET_ALL_IMPRU_ADMINS_REQUEST";
export const GET_ALL_IMPRU_ADMINS_FAILURE = "GET_ALL_IMPRU_ADMINS_FAILURE";
export const GET_ALL_IMPRU_ADMINS_SUCCESS = "GET_ALL_IMPRU_ADMINS_SUCCESS";

export const GET_IMPRU_ADMIN_DETAIL_REQUEST = "GET_IMPRU_ADMIN_DETAIL_REQUEST";
export const GET_IMPRU_ADMIN_DETAIL_FAILURE = "GET_IMPRU_ADMIN_DETAIL_FAILURE";
export const GET_IMPRU_ADMIN_DETAIL_SUCCESS = "GET_IMPRU_ADMIN_DETAIL_SUCCESS";

export const createImpruAdmin = (userData) => async (dispatch) => {
  const { name, email, phoneNumber, isAdmin } = userData;
  dispatch({
    type: CREATE_IMPRU_ADMIN_REQUEST,
  });
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({
    name,
    email,
    phoneNumber,
    isAdmin,
  });
  try {
    //console.log(body);

    const res = await axios.post(
      `${baseUrl}/api/impru-admin/create-new`,
      body,
      config
    );
    console.log(res);
    if (res.status === 200) {
      dispatch({
        type: CREATE_IMPRU_ADMIN_SUCCESS,
        payload: res.data.message,
      });
      dispatch(setAlert({message:res.data.message,alertType:'success'}))
    } else {
      dispatch({
        type: CREATE_IMPRU_ADMIN_FAILURE,
        payload: res.data.message,
      });
      dispatch(setAlert({message:res.data.messages[0].msg,alertType:'error'}))
    }
  } catch (err) {
  //  console.log(err.response.data);
   dispatch(setAlert({message:err.response.data.messages[0].msg,alertType:'error'}))
    dispatch({
      type: CREATE_IMPRU_ADMIN_FAILURE,
      payload: err.response.data.message,
    });
  }
};

export const getImpruAdminDetail = (userId) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_IMPRU_ADMIN_DETAIL_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.get(
        `${baseUrl}/api/impru-admin/${userId}`,
        config
      );
      console.log(res);
      if (res.status === 200) {
        dispatch({
          type: GET_IMPRU_ADMIN_DETAIL_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_IMPRU_ADMIN_DETAIL_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      console.log("get IMPRU_ADMIN by id fail");

      dispatch({
        type: GET_IMPRU_ADMIN_DETAIL_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

export const getAllImpruAdmins = (mainInstituteId) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch({
    type: GET_ALL_IMPRU_ADMINS_REQUEST,
  });
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.get(`${baseUrl}/api/impru-admin/all`, config);
      console.log(res);
      if (res.status === 200) {
        dispatch({
          type: GET_ALL_IMPRU_ADMINS_SUCCESS,
          payload: res.data.impruUsers,
        });
      } else {
        dispatch({
          type: GET_ALL_IMPRU_ADMINS_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      console.log("get IMPRU_ADMIN failure");

      dispatch({
        type: GET_ALL_IMPRU_ADMINS_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

export const editImpruAdmin = (id, userData) => async (dispatch) => {
  const { name, email, phoneNumber } = userData;
  dispatch({
    type: EDIT_IMPRU_ADMIN_REQUEST,
  });
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({
        name,
        email,
        phoneNumber,
      });

      const res = await axios.put(`${baseUrl}/api/impru-admin`, body, config);

      if (res.status === 2000) {
        dispatch({
          type: EDIT_IMPRU_ADMIN_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: EDIT_IMPRU_ADMIN_FAILURE,
          payload: res.data.message,
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_IMPRU_ADMIN_FAILURE,
        payload: err.response.data.message,
      });
    }
  }
};

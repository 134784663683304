import {
  CREATE_STUDENT_REQUEST,
  CREATE_STUDENT_FAILURE,
  CREATE_STUDENT_SUCCESS,
  CREATE_MULTIPLE_STUDENTS_FAILURE,
  CREATE_MULTIPLE_STUDENTS_REQUEST,
  CREATE_MULTIPLE_STUDENTS_SUCCESS,
  EDIT_STUDENT_REQUEST,
  EDIT_STUDENT_FAILURE,
  EDIT_STUDENT_SUCCESS,
  GET_GROUP_STUDENTS_REQUEST,
  GET_GROUP_STUDENTS_FAILURE,
  GET_GROUP_STUDENTS_SUCCESS,
  GET_STUDENT_DETAIL_REQUEST,
  GET_STUDENT_DETAIL_FAILURE,
  GET_STUDENT_DETAIL_SUCCESS,
} from "../actions/Students";

const initialState = {
  students: [],
  student: null,
  isLoading: false,
  error: null,
  message: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_STUDENT_REQUEST:
    case CREATE_MULTIPLE_STUDENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case CREATE_STUDENT_SUCCESS:
    case CREATE_MULTIPLE_STUDENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
      };

    case CREATE_STUDENT_FAILURE:
    case CREATE_MULTIPLE_STUDENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case EDIT_STUDENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_STUDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
      };

    case EDIT_STUDENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GET_GROUP_STUDENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_GROUP_STUDENTS_SUCCESS:
      return {
        ...state,
        students: action.payload,
        isLoading: false,
      };
    case GET_GROUP_STUDENTS_FAILURE:
      return {
        ...state,

        isLoading: false,
        error: action.payload,
      };
    case GET_STUDENT_DETAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_STUDENT_DETAIL_SUCCESS:
      return {
        ...state,
        student: action.payload,
        isLoading: false,
      };
    case GET_STUDENT_DETAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}

import React from "react";
import {} from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";

const StudentListItem = (props) => {
  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar alt="Remy Sharp" src={props.student.imageUrl} />
      </ListItemAvatar>
      <ListItemText primary={props.student.name} />
    </ListItem>
  );
};

export default StudentListItem;
